import React, { useEffect } from 'react';
import { Box, Card, CardHeader, CardContent, Typography, Stack, Skeleton } from '@mui/material';
import { ResponsiveLine } from '@nivo/line';
import { useSiteBandwidthUsage } from 'api/siteMetrics';
import { useParams } from 'react-router-dom';
import ReportingNoData from 'component/svg/chart/no-data.svg?react';
import LoadingContainer from '../../partial/LoadingContainer';
import { useTranslation } from 'react-i18next';
import { getTickFormatForDuration } from 'utils/reporting';
import { tooltip } from 'theme/custom/tooltip';
import { formatDataSize } from 'utils/number';
import { DurationSelect } from './DurationSelect';
import { GraphCard } from 'component/base/GraphCard';

interface SiteActivityCardProps {
  readonly title: string;
  readonly onDurationChange?: (duration: string) => void;
}

export function SiteActivityCard({ title, onDurationChange }: SiteActivityCardProps) {
  const { siteId = '' } = useParams<{ siteId: string }>();

  const [duration, setDuration] = React.useState<string>('24h');
  const { isLoading, data } = useSiteBandwidthUsage({
    id: siteId,
    duration,
  });
  const { t } = useTranslation();

  useEffect(() => {
    if (onDurationChange) {
      onDurationChange(duration);
    }
  }, [duration, onDurationChange]);

  const lineData =
    data?.data?.data?.viewer.zones[0].series
      .map(datum => {
        return {
          y: datum.sum.edgeResponseBytes,
          x: datum.dimensions.ts,
        };
      })
      .sort((a, b) => new Date(b.x).getTime() - new Date(a.x).getTime()) ?? [];
  const hasData = !(!lineData || lineData.length === 0);

  const renderChart = () => {
    if (hasData) {
      return (
        <ResponsiveLine
          data={[
            {
              id: 'Data Transfer',
              data: lineData,
            },
          ]}
          // TODO - get this color from theme palette
          colors={['#095bb4']}
          animate={false}
          margin={{ top: 30, right: 30, bottom: 30, left: 70 }}
          xScale={{ type: 'time', format: '%Y-%m-%d', useUTC: true }}
          yScale={{ type: 'linear', min: 0, max: 'auto', stacked: true, reverse: false }}
          yFormat={val => formatDataSize(Number(val) / 1000)}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            format: getTickFormatForDuration(duration),
            tickSize: 0,
            tickPadding: 5,
          }}
          axisLeft={{
            tickSize: 0,
            tickPadding: 5,
            tickRotation: 0,
            format: val => formatDataSize(val / 1000),
          }}
          enableGridX={false}
          curve={'linear'}
          enablePoints={false}
          useMesh
          enableSlices="x"
          xFormat="time:%Y-%m-%d"
          sliceTooltip={({ slice }) => {
            return (
              <Box sx={tooltip}>
                {slice.points.map(point => (
                  <Box key={point.id}>
                    <Typography variant="caption">{point.data.xFormatted}</Typography>
                    <Box>
                      <strong>{point.data.yFormatted}</strong> {point.serieId}
                    </Box>
                  </Box>
                ))}
              </Box>
            );
          }}
          {...(lineData.length === 1
            ? {
                enablePoints: true,
                pointSize: 10,
                enableGridX: true,
              }
            : null)}
        />
      );
    }

    // TODO implement empty state
    // return (
    //   <Box>
    //     <ReportingNoData />
    //     <Typography variant="h3">{t('no_data_to_display')}</Typography>
    //     <Typography>{t('no_data_to_display_description')}</Typography>
    //   </Box>
    // );
  };

  // if (!hasData) {
  //   return (
  //     <Card sx={{ minHeight: '300px' }}>
  //       <CardContent>{'TODO - temp has no data, get from Josip'}</CardContent>
  //     </Card>
  //   );
  // }

  if (isLoading) {
    return <Skeleton variant="rounded" height="172px" />;
  }

  return (
    <Card>
      <CardHeader
        action={<DurationSelect value={duration} onChange={setDuration} />}
        title={title}
      />
      <CardContent>
        <Box
          sx={{
            display: 'grid',
            gap: 2,
            gridTemplateColumns: ['1fr', 'repeat(2, 1fr)', 'repeat(4, 1fr)'],
          }}
        >
          <GraphCard color="oranges.500" label={t('total_requests')} value={5454} />
          <GraphCard
            color="greens.500"
            label={t('served_by_edge')}
            value={32}
            valuePercentage={5.34}
          />
          <GraphCard
            color="reds.500"
            label={t('served_by_origin')}
            value={534}
            valuePercentage={45.3}
          />
          <GraphCard color="blues.500" label={t('waf_events')} value={8756} />
        </Box>

        <Box>{isLoading ? <LoadingContainer /> : renderChart()}</Box>
      </CardContent>
    </Card>
  );
}
