import { useState } from 'react';
import { Chip, ChipProps, Stack } from '@mui/material';
import { SiteDetail, SiteUser, useDeleteSiteUser, useSiteUsers } from 'api/site';
import { useParams } from 'react-router-dom';
import { ConfirmationDialog } from 'component/base/ConfirmDialog';
import { useTranslation } from 'react-i18next';
import { AddSiteUser } from './AddSiteUser';
import { useUserResendSiteInvite } from 'api/auth';
import { useSnackbar } from 'component/hooks/useSnackbar';
import { Table } from 'component/base/Table';
import { TableColumnType } from 'component/base/Table/types';
import DeleteDustbin from '../icons/DeleteDustbin.svg?react';
import Envelope from '../icons/Envelope.svg?react';
import { usePaginatedListState } from 'component/hooks/usePaginatedListState';
import { InfoTooltip } from '../base/InfoTooltip';
import { EmptyState } from '../base/EmptyState';
import UserThree from '../icons/UserThree.svg?react';

interface Props {
  readonly siteDetails?: SiteDetail;
}

/**
 * eventually this will include name and avatar URL, will bring up to @dkoston
 */
function NameCell({ siteUser }: { readonly siteUser: SiteUser }) {
  return siteUser.email;
}

export function SiteUserList({ siteDetails }: Props) {
  const { siteId = '' } = useParams<{ siteId: string }>();
  const { mutateAsync } = useDeleteSiteUser(siteId);
  const [userToDelete, setUserToDelete] = useState<SiteUser | null>(null);
  const { enqueueSnackbar } = useSnackbar();
  const resendInvite = useUserResendSiteInvite(siteId);

  const tableState = usePaginatedListState({ urlStatePrefix: 'users' });

  const users = useSiteUsers(siteId, tableState);

  const { t } = useTranslation();

  const columns: (TableColumnType<SiteUser> | null)[] = [
    {
      label: t('email'),
      key: 'email',
      renderValue: siteUser => <NameCell siteUser={siteUser} />,
      sortable: true,
    },
    {
      label: t('status'),
      key: 'status',
      renderValue: siteUser => {
        if (!siteUser.status) {
          return '';
        }

        let color: ChipProps['color'] = 'success';
        if (siteUser.status === 'Declined') {
          color = 'error';
        }
        if (siteUser.status === 'Invited') {
          color = 'warning';
        }

        return <Chip variant="tag" label={t(`statuses.${siteUser.status}`)} color={color} />;
      },
      sortable: true,
    },
  ];

  const handleConfirmDelete = async () => {
    if (!userToDelete || !userToDelete.user_id) {
      throw new Error('User ID is missing');
    }

    await mutateAsync(userToDelete.user_id);
    setUserToDelete(null);
  };

  const handleSendInvite = async (siteUser: SiteUser) => {
    if (!siteUser.user_id) {
      throw new Error('User ID is missing');
    }
    await resendInvite.mutateAsync(siteUser.user_id);
    enqueueSnackbar(t('user_resend_invite_success', { userName: siteUser.email }), {
      key: 'resendInviteSuccess',
      variant: 'success',
    });
  };

  return (
    <>
      {userToDelete ? (
        <ConfirmationDialog
          action="delete"
          title={t('user_delete')}
          description={t('user_delete_confirmation_description', {
            user: userToDelete.email,
          })}
          onClose={() => setUserToDelete(null)}
          onConfirm={handleConfirmDelete}
        />
      ) : null}
      <Table
        title={
          <Stack direction="row" alignItems="center">
            {t('site_users')}
            <InfoTooltip size="small">{t('site_users_description')}</InfoTooltip>
          </Stack>
        }
        label="Site Users Table"
        rowActions={[
          {
            onClick: handleSendInvite,
            icon: <Envelope />,
            label: t('resend_email'),
          },
          {
            icon: <DeleteDustbin />,
            label: t('remove'),
            onClick: setUserToDelete,
            hidden: siteUser => siteUser.status !== 'Invited',
            color: 'reds.500',
          },
        ]}
        columns={columns}
        data={users.data?.data.result || []}
        totalRowCount={users.data?.data.metadata?.total || 0}
        isLoading={users.isLoading}
        state={tableState}
        enablePagination
        enableSearch
        searchPlaceholder={t('search_users')}
        emptyState={
          <EmptyState
            icon={<UserThree />}
            title={t('no_data_yet')}
            action={<AddSiteUser siteDetails={siteDetails} />}
          />
        }
      >
        <AddSiteUser siteDetails={siteDetails} />
      </Table>
    </>
  );
}
