import { TableCell, TableHead as MuiTableHead, TableRow, TableSortLabel } from '@mui/material';
import { PaginatedListParams, PaginatedListState } from 'component/hooks/usePaginatedListState';
import { TableColumnType, TableRowType } from './types';
import { actionsColumn } from './TableRowActions';

interface Props<T extends TableRowType> {
  readonly columns: TableColumnType<T>[];
  readonly hasActionsColumn: boolean;
  readonly sortBy: PaginatedListParams['sortBy'];
  readonly sortDirection: PaginatedListParams['sortDirection'];
  readonly onSort: PaginatedListState['setSort'];
}

export const TableHead = <T extends TableRowType>({
  columns,
  hasActionsColumn,
  sortBy,
  sortDirection,
  onSort,
}: Props<T>) => {
  const displayedColumns = hasActionsColumn ? [...columns, actionsColumn] : columns;

  const handleSort = (key: any) => () => {
    if (key === sortBy && sortDirection === 'desc') {
      onSort(String(key), 'asc');
    } else if (key === sortBy && sortDirection === 'asc') {
      onSort(undefined, undefined);
    } else {
      onSort(String(key), 'desc');
    }
  };

  return (
    <MuiTableHead component="div" role="rowgroup">
      <TableRow component="div" role="row">
        {displayedColumns.map(column => (
          <TableCell
            component="div"
            role="columnheader"
            key={column.label}
            align={column.align || 'left'}
            sortDirection={sortBy === column.key ? sortDirection : false}
          >
            {column.sortable ? (
              <TableSortLabel
                active={sortBy === column.key}
                direction={sortDirection}
                onClick={handleSort(column.key)}
                hideSortIcon={!column.sortable}
              >
                {column.label}
              </TableSortLabel>
            ) : (
              column.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </MuiTableHead>
  );
};
