import React from 'react';
import { Button, InputAdornment, Stack } from '@mui/material';
import { useForm } from 'react-hook-form';
import { CreateFtpAccountData, useCreateFtpAccount } from 'api/ftpAccounts';
import { useParams } from 'react-router-dom';
import { TextField } from '../base/forms/TextField';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'component/hooks/useSnackbar';
import passwordStrength from 'password-strength-calc';
import { FormDialog } from '../base/dialogs/FormDialog';

interface Props {
  readonly domain: string;
  readonly onSuccess: () => Promise<unknown>;
}

export function AddFtpAccount({ domain, onSuccess }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const [showAddDialog, setShowAddDialog] = React.useState<boolean>(false);
  const { siteId = '' } = useParams<{ siteId: string }>();
  const { mutateAsync, isPending } = useCreateFtpAccount({
    id: siteId,
  });
  const strippedDomain = domain.replace('www.', '');
  const methods = useForm<CreateFtpAccountData>({
    defaultValues: {
      domain: strippedDomain,
      username: '',
      password: '',
      homedir: '',
      quota: 0,
    },
  });
  const { reset } = methods;
  const { t } = useTranslation();

  const onSubmit = async (data: CreateFtpAccountData) => {
    if (passwordStrength(data.password) < 60) {
      enqueueSnackbar(t('ftp_account_password_error'), {
        key: 'passwordStrengthError',
        variant: 'error',
      });
      return;
    }

    await mutateAsync({
      ...data,
      homedir: data.homedir ? `public_html/${data.homedir}` : 'public_html',
    });
    await onSuccess();
    reset();
    setShowAddDialog(false);
  };

  function handleClose() {
    reset();
    setShowAddDialog(false);
  }

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          setShowAddDialog(true);
        }}
      >
        {t('add_ftp_account')}
      </Button>
      <FormDialog
        form={
          <Stack spacing={4} direction="column">
            <TextField
              name="username"
              label={t('username')}
              disabled={isPending}
              fullWidth
              endAdornment={
                <InputAdornment
                  position="end"
                  disableTypography
                >{`@${strippedDomain}`}</InputAdornment>
              }
              rules={{ required: true }}
            />
            <TextField
              name="password"
              disabled={isPending}
              fullWidth
              label={t('password')}
              type="password"
              rules={{ required: true }}
            />
            <TextField
              name="homedir"
              disabled={isPending}
              fullWidth
              label={t('directory')}
              startAdornment={
                <InputAdornment position="start" disableTypography>
                  {'public_html/'}
                </InputAdornment>
              }
            />
          </Stack>
        }
        methods={methods}
        onSubmit={onSubmit}
        onClose={handleClose}
        open={showAddDialog}
        title={t('create_ftp_account')}
        submitText={t('create')}
      />
    </>
  );
}
