import { Avatar } from '@mui/material';

export default function UserAvatar({
  firstname,
  lastname,
  size,
}: {
  readonly firstname?: string;
  readonly lastname?: string;
  readonly size: number;
}) {
  return (
    <Avatar
      sx={{
        display: 'inline-flex',
        marginRight: '5px',
        width: `${size}px`,
        height: `${size}px`,
        fontSize: '14px',
      }}
      alt={`${firstname} ${lastname}`}
    >
      {firstname?.charAt(0) ?? ''}
      {lastname?.charAt(0) ?? ''}
    </Avatar>
  );
}
