import { PaginatedListState } from 'component/hooks/usePaginatedListState';
import { AccessLogFilterKey, useFilterLabels } from './common';
import { Chip, Stack, Typography } from '@mui/material';
import Close from 'component/new_design/icons/Close.svg?react';
import { useTranslation } from 'react-i18next';

interface AccessLogAppliedFiltersProps {
  readonly state: PaginatedListState<AccessLogFilterKey>;
  readonly onFilterOpen: (filter: AccessLogFilterKey) => void;
  readonly totalResults: number;
}

export const AccessLogAppliedFilters = ({
  state,
  onFilterOpen,
  totalResults,
}: AccessLogAppliedFiltersProps) => {
  const { t } = useTranslation();
  const { getLabel } = useFilterLabels();

  const appliedFilterEntries = Object.entries(state.params.filters || {}).filter(
    ([key, value]) => value !== undefined && key !== 'duration'
  ) as [AccessLogFilterKey, string][];

  return appliedFilterEntries.length ? (
    <Stack direction="row" gap={2} alignItems="center" mb={4}>
      {appliedFilterEntries.map(([key, value]) => (
        <Chip
          key={key}
          variant="filter"
          label={
            <>
              <Typography variant="caption" component="span">
                {getLabel(key)}:{' '}
              </Typography>
              <Typography variant="caption" component="span" fontWeight={600}>
                {value}
              </Typography>
            </>
          }
          deleteIcon={<Close />}
          onDelete={() => state.setFilter(key, undefined)}
          onClick={() => onFilterOpen(key)}
        />
      ))}
      <Typography variant="body2" component="span" ml={2}>
        {t('total_results', { count: totalResults })}
      </Typography>
    </Stack>
  ) : null;
};
