import { Card, CardContent, Chip, Stack, Typography } from '@mui/material';
import { IconWrapper } from 'component/new_design/base/IconWrapper';
import Circle from 'component/new_design/icons/Circle.svg?react';

interface GraphCardProps {
  readonly color: string;
  readonly label: string;
  readonly value: number;
  readonly valuePercentage?: number;
  readonly loading?: boolean;
}

export const GraphCard = ({ color, label, value, valuePercentage, loading }: GraphCardProps) => {
  return (
    <Card color="metrics" sx={{ p: 4 }}>
      <Stack gap={1}>
        <Stack direction="row" gap={2} alignItems="center">
          <IconWrapper size="xxs" color={color}>
            <Circle />
          </IconWrapper>
          <Typography variant="body2">{label}</Typography>
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="space-between" ml={4}>
          <Typography variant="h4">{loading ? '-' : value}</Typography>
          {!loading && typeof valuePercentage === 'number' ? (
            <Chip variant="badge" color="info" label={`${valuePercentage.toFixed(1)}%`} />
          ) : null}
        </Stack>
      </Stack>
    </Card>
  );
};
