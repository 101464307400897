import { Card, CardContent, Typography, Stack } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { JSX } from 'react';

export default function CardTab({
  IconComponent,
  title,
  to,
}: {
  readonly IconComponent: (props: { readonly selected: boolean }) => JSX.Element;
  readonly title: string;
  readonly to: string;
}) {
  const { pathname } = useLocation();
  const selected = pathname.startsWith(to);

  return (
    <Link
      style={{
        textDecoration: 'none',
      }}
      to={to}
    >
      <Card
        sx={theme => ({
          minWidth: '65px',
          width: '100%',
          height: '86px',
          backgroundColor: selected ? theme.palette.common.black : theme.palette.common.white,
        })}
      >
        <Stack justifyContent="space-between" p={2} height="100%">
          <IconComponent selected={selected} />
          <Typography variant="body2" color={selected ? 'common.white' : 'common.black'}>
            {title}
          </Typography>
        </Stack>
      </Card>
    </Link>
  );
}
