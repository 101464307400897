import { Box, Stack, Typography } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';

interface CompactTabsProps {
  readonly label: string;
  readonly tabs: { title: string; href: string }[];
}

export const CompactTabs = ({ tabs, label }: CompactTabsProps) => {
  const { pathname } = useLocation();

  const isActive = (href: string) => {
    return href === pathname;
  };

  const getTarget = (href: string) => {
    return href.startsWith('/') ? undefined : '_blank';
  };

  return (
    <Stack
      direction="row"
      component="nav"
      aria-label={label}
      sx={{
        p: 1,
        gap: 1,
        backgroundColor: 'common.white',
        borderRadius: 'var(--border-radius-m)',
      }}
    >
      {tabs.map(({ href, title }) => (
        <Link
          to={href}
          key={href}
          target={getTarget(href)}
          style={{ textDecoration: 'unset', color: 'unset' }}
          aria-current={isActive(href) ? 'page' : undefined}
        >
          <Box
            sx={{
              px: 3,
              py: 2,
              textDecoration: 'none',
              transition: 'all 0.2s',
              borderRadius: 'var(--border-radius-s)',
              color: 'greys.500',
              backgroundColor: 'common.white',
              'a:focus &, a:hover &': {
                color: 'greys.700',
                backgroundColor: 'greys.100',
              },
              'a[aria-current=page] &': {
                color: 'common.white',
                backgroundColor: 'greys.900',
              },
              'a[aria-current=page]:focus &, a[aria-current=page]:focus &': {
                color: 'greys.100',
                backgroundColor: 'greys.800',
              },
            }}
          >
            <Typography
              variant="body2"
              sx={{ textDecoration: 'none', fontWeight: isActive(href) ? 600 : 400 }}
            >
              {title}
            </Typography>
          </Box>
        </Link>
      ))}
    </Stack>
  );
};
