import { Box, BoxProps } from '@mui/material';
import { ReactNode } from 'react';

interface IconWrapperProps {
  readonly size?: 'xxs' | 'xs' | 's' | 'm' | 'l';
  readonly color?: BoxProps['color'];
  readonly children: ReactNode;
}

const sizeMap = {
  xxs: '8px',
  xs: '14px',
  s: '16px',
  m: '24px',
  l: '32px',
};

export const IconWrapper = ({ size, color, children }: IconWrapperProps) => {
  return (
    <Box
      sx={{
        width: sizeMap[size ?? 'm'],
        height: sizeMap[size ?? 'm'],
        color,
        display: 'grid',
        placeItems: 'center',
        svg: { width: '100%', height: '100%' },
        flex: 'none',
      }}
    >
      {children}
    </Box>
  );
};
