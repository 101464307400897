import { useCallback, useContext } from 'react';
import { PostMessageContext } from 'component/postMessage/PostMessageContext';
import { isInIframe } from 'utils/iframe';

export const useExternalLink = () => {
  const { sendMessage } = useContext(PostMessageContext);

  const openExternalLink = useCallback(
    ({ url, iframeUrl, relative }: { url: string; iframeUrl?: string; relative?: boolean }) => {
      if (isInIframe()) {
        sendMessage(
          JSON.stringify({
            type: 'externalLink',
            data: {
              relative: relative ?? false,
              link: url ?? iframeUrl,
            },
          })
        );
      } else {
        window.open(url);
      }
    },
    [sendMessage]
  );

  return { openExternalLink };
};
