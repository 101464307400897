import { linkHelper } from 'linkHelper';
import { Route, Routes } from 'react-router-dom';
import Authenticated from '../layout/Authenticated';
import { lazy, Suspense, useState } from 'react';
import { Box, CssBaseline, PaletteMode, ThemeProvider } from '@mui/material';
import { onCreateTheme, StoredColorMode } from '../theme';
import '../theme/base/variables.css';
import '../theme/base/reset.css';
import '../theme/base/fonts.css';
import SiteDetails from '../view/SiteDetails';
import { COLOR_MODE_KEY } from '../../../constants';
// TODO - will be implemented later
// import AccountAndSecurity from '../view/AccountAndSecurity';
// import Users from '../view/Users';
// import SiteTemplates from '../view/SiteTemplates';

const Dashboard = lazy(() => import('component/new_design/view/Dashboard'));
const Sites = lazy(() => import('component/new_design/view/Sites'));

export default function App() {
  const systemTheme: PaletteMode =
    typeof window !== 'undefined' && window.matchMedia('(prefers-color-scheme: dark)').matches
      ? 'dark'
      : 'light';
  console.log(systemTheme);
  const storedMode =
    (window.localStorage.getItem(COLOR_MODE_KEY) as StoredColorMode | null) ?? 'light';
  const [colorMode, setColorMode] = useState<StoredColorMode>(storedMode);
  const onColorModeChange = (mode: StoredColorMode) => {
    window.localStorage.setItem(COLOR_MODE_KEY, mode);
    setColorMode(mode);
  };
  const theme = onCreateTheme(colorMode === 'auto' ? systemTheme : colorMode);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Routes>
        <Route
          path={linkHelper.newDesign.dashboard.path}
          element={
            <Authenticated colorMode={colorMode} onColorModeChange={onColorModeChange}>
              <Suspense fallback={null}>
                <Dashboard />
              </Suspense>
            </Authenticated>
          }
        />
        <Route
          path={linkHelper.newDesign.accountAndSecurity.path}
          element={
            <Authenticated colorMode={colorMode} onColorModeChange={onColorModeChange}>
              <Suspense fallback={null}>
                {/* <AccountAndSecurity /> */}
                <Box />
              </Suspense>
            </Authenticated>
          }
        />
        <Route
          path={linkHelper.newDesign.users.path}
          element={
            <Authenticated colorMode={colorMode} onColorModeChange={onColorModeChange}>
              <Suspense fallback={null}>
                {/* <Users /> */}
                <Box />
              </Suspense>
            </Authenticated>
          }
        />

        <Route path={linkHelper.newDesign.sites.path}>
          <Route
            index
            element={
              <Authenticated colorMode={colorMode} onColorModeChange={onColorModeChange}>
                <Suspense fallback={null}>
                  <Sites />
                </Suspense>
              </Authenticated>
            }
          />
          <Route
            path={linkHelper.newDesign.sites.templates.path}
            index
            element={
              <Authenticated colorMode={colorMode} onColorModeChange={onColorModeChange}>
                <Suspense fallback={null}>
                  {/* <SiteTemplates /> */}
                  <Box />
                </Suspense>
              </Authenticated>
            }
          />
          <Route
            path={linkHelper.newDesign.sites.siteDetails.path}
            element={
              <Authenticated colorMode={colorMode} onColorModeChange={onColorModeChange}>
                <Suspense fallback={null}>
                  <SiteDetails />
                </Suspense>
              </Authenticated>
            }
          />
        </Route>
      </Routes>
    </ThemeProvider>
  );
}
