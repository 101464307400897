import { useTranslation } from 'react-i18next';
import ChevronDown from '../icons/ChevronDown.svg?react';
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Divider,
  Popover,
  Stack,
  Typography,
} from '@mui/material';
import { useEffect, useId, useState } from 'react';
import Envelope from '../icons/Envelope.svg?react';
import { IconWrapper } from '../base/IconWrapper';

const clampProgress = (value: number) => Math.min(Math.max(value, 0), 100);

const TaskProgressIndicator = ({ progress }: { readonly progress: number }) => {
  const { t } = useTranslation();

  if (progress === 100) {
    return <Chip variant="tag" label={t('completed')} color="success" />;
  } else if (progress > 0) {
    return <Chip variant="tag" label={`${progress}%`} color="secondary" />;
  } else {
    return <CircularProgress size={18} />;
  }
};

export const Tasks = () => {
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const [mockedProgress, setMockedProgress] = useState(0);

  const buttonId = useId();
  const popoverId = useId();

  const tasks = [
    { name: 'Cloning website', domain: 'myname.net', progress: clampProgress(mockedProgress) },
    {
      name: 'Staging creation',
      domain: 'hellowonderul.com',
      progress: clampProgress(mockedProgress - 50),
    },
  ];

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMockedProgress(39);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setMockedProgress(prev => prev + 4);
    }, 800);

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <Button
        id={buttonId}
        aria-haspopup="true"
        aria-controls={popoverId}
        aria-expanded={anchorEl ? 'true' : 'false'}
        variant="contained"
        color="header"
        size="large"
        sx={{ flex: 'none', gap: 2 }}
        startIcon={<CircularProgress color="primary" size={18} />}
        endIcon={<ChevronDown />}
        onClick={handleOpen}
      >
        {t('global_search.still_working', {
          value: 39,
        })}
      </Button>
      {!!anchorEl && (
        <Popover
          id={popoverId}
          open
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <Stack p={3} gap={1} sx={{ width: '400px' }}>
            <Typography variant="caption" fontWeight={600}>
              {t('processing')}
            </Typography>
            <Typography variant="caption" color="greys.500">
              {t('tasks_processing_description')}
            </Typography>
          </Stack>

          <Divider />

          <Stack p={1}>
            {tasks.map(task => (
              <Stack
                key={task.name}
                p={2}
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Stack gap={0.5}>
                  <Typography variant="body2" fontWeight={600}>
                    {task.name}
                  </Typography>
                  <Typography variant="caption" color="greys.500">
                    {task.domain}
                  </Typography>
                </Stack>
                <TaskProgressIndicator progress={task.progress} />
              </Stack>
            ))}
          </Stack>

          <Divider />

          <Box p={3} sx={{ textAlign: 'right' }}>
            <Button
              variant="text"
              startIcon={
                <IconWrapper size="xs">
                  <Envelope />
                </IconWrapper>
              }
              size="small"
            >
              {t('send_email_when_completed')}
            </Button>
          </Box>
        </Popover>
      )}
    </>
  );
};
