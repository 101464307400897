import { useTheme } from '@mui/material';

// selected flips the colors so figured the easiet way to tackle is at the component level instead of doing it at the parent level with css
export default function PluginDuotone({ selected }: { readonly selected: boolean }) {
  // this will changes once we select the palette approach we want
  const theme = useTheme();

  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.69673 2.5C5.48642 2.49998 5.28158 2.49995 5.10812 2.51413C4.91763 2.52969 4.69285 2.56641 4.46666 2.68166C4.15306 2.84145 3.89809 3.09642 3.7383 3.41002C3.62305 3.63621 3.58633 3.86099 3.57077 4.05148C3.55659 4.22494 3.55662 4.42976 3.55664 4.64008L3.55664 6.68266C3.55664 6.90423 3.64488 7.11668 3.80185 7.27305C3.95883 7.42942 4.17161 7.51684 4.39318 7.51599L8.55984 7.5C9.01883 7.49824 9.38998 7.12566 9.38998 6.66667L9.38998 4.64009C9.39001 4.42977 9.39003 4.22495 9.37586 4.05148C9.36029 3.86099 9.32357 3.63621 9.20832 3.41002C9.04853 3.09642 8.79357 2.84145 8.47996 2.68166C8.25378 2.56641 8.029 2.52969 7.8385 2.51413C7.66504 2.49995 7.46022 2.49998 7.2499 2.5L5.69673 2.5Z"
        fill={theme.palette.primary.light}
      />
      <path
        d="M13.1967 2.5C12.9864 2.49998 12.7816 2.49995 12.6081 2.51413C12.4176 2.52969 12.1928 2.56641 11.9667 2.68166C11.6531 2.84145 11.3981 3.09642 11.2383 3.41002C11.1231 3.63621 11.0863 3.86099 11.0708 4.05148C11.0566 4.22494 11.0566 4.42975 11.0566 4.64006L11.0566 6.66667C11.0566 7.12566 11.4278 7.49824 11.8868 7.5L16.0534 7.51599C16.275 7.51684 16.4878 7.42942 16.6448 7.27305C16.8017 7.11668 16.89 6.90423 16.89 6.68266L16.89 4.6401C16.89 4.42978 16.89 4.22495 16.8759 4.05148C16.8603 3.86099 16.8236 3.63621 16.7083 3.41002C16.5485 3.09642 16.2936 2.84145 15.98 2.68166C15.7538 2.56641 15.529 2.52969 15.3385 2.51413C15.165 2.49995 14.9602 2.49998 14.7499 2.5L13.1967 2.5Z"
        // we could store this in the theme along side with the sdk color as a lighten of 30%
        fill={theme.palette.primary.light}
      />
      <path
        d="M5.35655 5.8335C4.91728 5.83348 4.53829 5.83347 4.22615 5.85897C3.8967 5.88589 3.56901 5.94532 3.2537 6.10598C2.78329 6.34567 2.40084 6.72812 2.16116 7.19852C2.0005 7.51384 1.94107 7.84152 1.91415 8.17097C1.88865 8.48311 1.88866 8.86207 1.88867 9.30134V11.3692C1.88867 12.2723 1.88866 13.0008 1.93686 13.5907C1.98648 14.1981 2.09132 14.7316 2.34281 15.2251C2.74229 16.0091 3.3797 16.6466 4.16371 17.046C4.65729 17.2975 5.19078 17.4024 5.79815 17.452C6.38805 17.5002 7.1165 17.5002 8.01965 17.5002H12.4244C13.3275 17.5002 14.056 17.5002 14.6459 17.452C15.2532 17.4024 15.7867 17.2975 16.2803 17.046C17.0643 16.6466 17.7017 16.0091 18.1012 15.2251C18.3527 14.7316 18.4575 14.1981 18.5072 13.5907C18.5554 13.0008 18.5553 12.2724 18.5553 11.3693V9.30136C18.5554 8.86208 18.5554 8.48311 18.5299 8.17098C18.503 7.84152 18.4435 7.51384 18.2829 7.19852C18.0432 6.72812 17.6607 6.34567 17.1903 6.10598C16.875 5.94532 16.5473 5.88589 16.2179 5.85897C15.9057 5.83347 15.5268 5.83348 15.0875 5.8335H5.35655Z"
        fill={selected ? theme.palette.common.white : theme.palette.primary.main}
      />
    </svg>
  );
}
