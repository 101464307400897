import {
  Button,
  ButtonProps,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
  TypographyProps,
} from '@mui/material';
import { ReactNode, useId, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ThreeDotsMenu from 'component/new_design/icons/ThreeDotsMenu.svg?react';
import { IconWrapper } from './IconWrapper';

export interface DropdownAction {
  icon?: ReactNode;
  endIcon?: ReactNode;
  label: string;
  onClick: () => void;
  color?: TypographyProps['color'];
}

interface DropdownMenuProps {
  readonly actions: DropdownAction[];
  readonly label?: string;
  readonly buttonProps?: ButtonProps;
}

export const DropdownMenu = ({ actions, label, buttonProps }: DropdownMenuProps) => {
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const buttonId = useId();
  const menuId = useId();

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAction = (action: DropdownAction) => () => {
    action.onClick();
    handleClose();
  };

  return (
    <>
      <Button
        variant="icon"
        color="secondary"
        {...buttonProps}
        onClick={handleOpen}
        id={buttonId}
        aria-haspopup="true"
        aria-controls={menuId}
        aria-expanded={anchorEl ? 'true' : 'false'}
        aria-label={label || t('menu')}
      >
        {buttonProps?.children || <ThreeDotsMenu />}
      </Button>
      {!!anchorEl && (
        <Menu
          id={menuId}
          anchorEl={anchorEl}
          open={!!anchorEl}
          onClose={handleClose}
          MenuListProps={{ 'aria-labelledby': buttonId }}
        >
          {actions.map(action => (
            <MenuItem key={action.label} onClick={handleAction(action)}>
              {action.icon ? (
                <ListItemIcon>
                  <IconWrapper size="s" color={action.color || 'greys.400'}>
                    {action.icon}
                  </IconWrapper>
                </ListItemIcon>
              ) : null}
              <ListItemText>
                <Typography variant="body2" component="span" color={action.color || 'greys.900'}>
                  {action.label}
                </Typography>
              </ListItemText>
              {action.endIcon ? (
                <IconWrapper size="s" color={action.color || 'greys.400'}>
                  {action.endIcon}
                </IconWrapper>
              ) : null}
            </MenuItem>
          ))}
        </Menu>
      )}
    </>
  );
};
