import RocketShipSolid from '../icons/RocketShipSolid.svg?react';
import React from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Checkbox,
  Grid,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Popover,
  Snackbar,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import Alert from '@mui/material/Alert';
import { SiteDetail } from 'api/site';
import { WizardStep } from 'component/base/WizardStep';
import { useForm, FormProvider } from 'react-hook-form';
import isFQDN from 'validator/lib/isFQDN';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { ProgressiveButton } from 'component/base/ProgressiveButton';
import {
  useSetMainDomain,
  useUpdateSSLValidation,
  VerificationRecord,
  useForceValidationRecheck,
  useUpdateGoLiveMode,
} from 'api/domain';
import { CopyButton } from 'component/base/CopyButton';
import { TextField } from 'component/base/TextField';
import { Trans, useTranslation } from 'react-i18next';
import { getMainDomainInfo } from 'utils/site';
import { components } from 'openapi-types';
import { BaseDialog } from '../base/dialogs/BaseDialog';

const DNS_PROVIDER_CLOUDFLARE: string = 'cloudflare';

interface SetupInstructionsProps {
  readonly instructions: {
    key: string;
    data: any;
  }[];
  testId: string;
}

function SetupInstructions({ instructions, testId }: SetupInstructionsProps) {
  const { t } = useTranslation();

  return instructions.map(({ key, data }, index) => (
    <Box data-testid={`${testId}-${index + 1}`} key={data}>
      <Chip label={t('step_number', { number: index + 1 })} />
      <Typography>
        <Trans i18nKey={key} values={data} components={[<strong key="strong" />]} />
      </Typography>
    </Box>
  ));
}

interface DNSRecordsTableProps {
  readonly records: components['schemas']['ValidationRecord'][];
  readonly label: string;
  readonly hideTTLColumn?: boolean;
}

function DNSRecordsTable({ records, label, hideTTLColumn = false }: DNSRecordsTableProps) {
  const { t } = useTranslation();

  return (
    <>
      <Typography>{t('example_how_it_make_look')}</Typography>
      <TableContainer>
        <Table aria-label={label}>
          <TableHead>
            <TableRow>
              <TableCell>{t('type')}</TableCell>
              <TableCell>{t('name')}</TableCell>
              <TableCell>{t('value')}</TableCell>
              {!hideTTLColumn && <TableCell>{t('ttl')}</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {records.map(row => (
              <TableRow key={(row.type ?? '') + (row.name ?? '')}>
                <TableCell>{row.type?.toUpperCase()}</TableCell>
                <TableCell>
                  <strong>{row.name ?? ''}</strong>
                  <CopyButton value={row.name ?? ''} />
                </TableCell>
                <TableCell>
                  <strong>{row.value ?? ''}</strong>
                  <CopyButton value={row.value ?? ''} />
                </TableCell>
                {!hideTTLColumn && <TableCell>{t('automatic')}</TableCell>}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

interface StepProps {
  readonly siteDetails?: SiteDetail;
  readonly setActiveStep: (step: number) => unknown;
}

function UpdateDns({ siteDetails }: Pick<StepProps, 'siteDetails'>) {
  const { t } = useTranslation();

  const hardcodedIP = '104.19.154.92';
  const siteId = siteDetails?.id?.toString() ?? '';
  const updateGoLiveMode = useUpdateGoLiveMode(siteId);
  const forceValidationRecheck = useForceValidationRecheck(siteId, true);
  const rocketUrl = siteDetails?.rocket_url ?? '';

  const { domainType, baseDomain, subdomain, dnsProvider } = getMainDomainInfo(siteDetails);

  let instructions: SetupInstructionsProps['instructions'];
  let dnsRecords: DNSRecordsTableProps['records'];

  if (domainType === 'subdomain') {
    instructions = [
      { key: 'update_dns_instructions_part_three_subdomain', data: { subdomain } },
      { key: 'update_dns_instructions_part_four', data: { value: rocketUrl } },
    ];

    dnsRecords = [{ type: 'cname', name: subdomain ?? undefined, value: rocketUrl }];
  } else {
    // Apex will have IP (non-cloudflare DNS) or rocket url (cloudflare DNS)
    const apexValue: string = dnsProvider !== DNS_PROVIDER_CLOUDFLARE ? hardcodedIP : rocketUrl;
    const apexType: components['schemas']['ValidationRecord']['type'] =
      dnsProvider !== DNS_PROVIDER_CLOUDFLARE ? 'a' : 'cname';

    instructions = [
      {
        key: 'update_dns_instructions_part_three_domain',
        data: { type: apexType.toUpperCase(), baseDomain },
      },
      { key: 'update_dns_instructions_part_four', data: { value: apexValue } },
      { key: 'update_dns_instructions_part_five_domain', data: { baseDomain } },
    ];

    dnsRecords = [
      { type: apexType, name: '@', value: apexValue },
      { type: 'cname', name: 'www', value: rocketUrl },
    ];
  }

  return (
    <Card>
      <CardHeader title="Update DNS" />
      <CardContent>
        <Typography variant="h3">{t('update_dns')}</Typography>
        <Typography>{t('update_dns_instructions_part_one')}</Typography>

        <Typography variant="h3">{t('setting_up')}</Typography>
        <Typography>{t('update_dns_instructions_part_two')}</Typography>

        <SetupInstructions instructions={instructions} testId="update-dns-step" />
        <DNSRecordsTable records={dnsRecords} label="Update DNS Example Table" />

        <Box>
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked
                onChange={async () => {
                  await updateGoLiveMode.mutateAsync({ hide_go_live: 1 });
                }}
                name="dontShowGoLive"
              />
            }
            label={t('dont_display_reminder_anymore')}
          />
        </Box>
        <ProgressiveButton
          text={t('ive_update_my_dns')}
          onClick={async () => {
            try {
              await forceValidationRecheck.mutateAsync();
            } catch (e) {
              // expect error if domain already in use
            }
            await updateGoLiveMode.mutateAsync({ hide_go_live: 1 });
          }}
          isLoading={updateGoLiveMode.isPending}
        />
      </CardContent>
    </Card>
  );
}

function ConfigureSSL({ siteDetails, setActiveStep }: StepProps) {
  const siteId = siteDetails?.id?.toString() ?? '';
  const [showForceRecheckToaster, setShowForceRecheckToaster] = React.useState<boolean>(false);
  const updateSSLValidation = useUpdateSSLValidation(siteId);
  const forceValidationRecheck = useForceValidationRecheck(siteId);

  const { baseDomain, subdomain, validationRecords, sslStatus } = getMainDomainInfo(siteDetails);

  const { t } = useTranslation();

  React.useEffect(() => {
    if (sslStatus === 'active') {
      setActiveStep(3);
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    const messages: string[] = forceValidationRecheck.data?.data?.messages ?? [];

    if (messages.includes('Domain is already active')) {
      setActiveStep(3);
    }

    if (forceValidationRecheck.status === 'success') {
      setShowForceRecheckToaster(true);
    }
  }, [siteDetails, forceValidationRecheck.status, setShowForceRecheckToaster, setActiveStep]);

  const records = validationRecords.map((record: VerificationRecord) => ({
    ...record,
    name:
      baseDomain === record.name ? (subdomain ?? '@') : record.name?.replace(`.${baseDomain}`, ''),
  }));

  const instructions = records.map(record => ({
    key: 'ready_to_go_live_verification',
    data: { type: record.type?.toUpperCase(), name: record.name, value: record.value },
  }));

  return (
    <Card>
      <CardHeader title={t('configure_ssl')} />
      <CardContent>
        <Snackbar
          TransitionProps={{
            appear: false,
            exit: false,
          }}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={showForceRecheckToaster}
          onClose={() => setShowForceRecheckToaster(false)}
        >
          <Alert
            variant="filled"
            onClose={() => setShowForceRecheckToaster(false)}
            severity="warning"
          >
            <Typography>{t('status_pending_validation')}</Typography>
            <Typography>{t('please_wait_or_check_status_later')}</Typography>
          </Alert>
        </Snackbar>
        <Typography variant="h3">{t('configure_free_ssl')}</Typography>
        <Typography>{t('configure_free_ssl_description')}</Typography>
        <SetupInstructions instructions={instructions} testId="configure-ssl-step" />
        <DNSRecordsTable records={records} hideTTLColumn label="SSL Records Table" />
        <ProgressiveButton
          text={t('ive_added_txt_records_continue')}
          onClick={async () => {
            await forceValidationRecheck.mutateAsync();
          }}
          isLoading={forceValidationRecheck.isPending}
        />
        <Box>
          <Chip label={t('note_on_this_step')} />
          <Typography>
            <Trans
              i18nKey="self_signed_certificate_step_1"
              components={[
                <Button
                  key="1"
                  color="primary"
                  onClick={async () => {
                    await updateSSLValidation.mutateAsync({
                      validation_method: 'http',
                    });
                    setActiveStep(3);
                  }}
                />,
              ]}
            />
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
}

function ChangeDomainName({ siteDetails, setActiveStep }: StepProps) {
  interface DomainFormValue {
    domain: string;
  }

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const methods = useForm<DomainFormValue>({
    defaultValues: {
      domain: '',
    },
    mode: 'onChange',
  });
  const { handleSubmit, formState } = methods;

  const setMainDomain = useSetMainDomain(siteDetails?.id?.toString() ?? '');

  const { t } = useTranslation();

  const onSubmit = async (data: DomainFormValue) => {
    await setMainDomain.mutateAsync({
      domain: `${data.domain.toLowerCase()}`,
    });
    setActiveStep(2);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <Card>
      <CardHeader title="Change Domain Name" />
      <CardContent>
        <Typography>
          {t('enter_your_domain_name')}
          <IconButton
            aria-describedby="productionVsStaging"
            onClick={handleClick}
            color="primary"
            size="large"
          >
            <HelpOutlineIcon />
          </IconButton>
          <Popover
            id="productionVsStaging"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            <Typography variant="h3">{t('enter_your_domain_name_information_title')}</Typography>
            <Typography>{t('enter_your_domain_name_information_description')}</Typography>
          </Popover>
        </Typography>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <TextField
              defaultValue=""
              name="domain"
              disabled={setMainDomain.isPending}
              placeholder={t('enter_your_domain_name_placeholder')}
              fullWidth
              rules={{
                required: true,
                validate: domain => {
                  return isFQDN(domain);
                },
              }}
              formatValue={val => {
                if (val.includes('https://www.')) {
                  return val.replace('https://www.', '');
                } else if (val.includes('http://www.')) {
                  return val.replace('http://www.', '');
                } else if (val.includes('https://')) {
                  return val.replace('https://', '');
                } else if (val.includes('http://')) {
                  return val.replace('http://', '');
                }
                return val;
              }}
              startAdornment={
                <InputAdornment
                  position="start"
                  disableTypography
                  component="button"
                  variant="outlined"
                >
                  http(s)://
                </InputAdornment>
              }
            />
            <Box marginTop={2}>
              <ProgressiveButton
                text={t('continue')}
                disabled={setMainDomain.isPending || !formState.isValid}
                onClick={async () => {
                  await handleSubmit(onSubmit)();
                }}
                isLoading={setMainDomain.isPending}
              />
            </Box>
          </form>
        </FormProvider>
      </CardContent>
    </Card>
  );
}

function ReadyToGoLiveDialog({ siteDetails }: { readonly siteDetails: SiteDetail }) {
  const { t } = useTranslation();

  const { sslStatus, dnsProvider, hostnameStatus, validationMethod } =
    getMainDomainInfo(siteDetails);

  let initialActiveStep = 1;

  if (validationMethod === 'http' || (sslStatus === 'active' && hostnameStatus === 'active')) {
    initialActiveStep = 3;
  }

  if ((sslStatus && sslStatus !== 'active') || (hostnameStatus && hostnameStatus !== 'active')) {
    initialActiveStep = 2;
  }

  if (dnsProvider === DNS_PROVIDER_CLOUDFLARE && sslStatus === 'active') {
    initialActiveStep = 3;
  }

  const [activeStep, setActiveStep] = React.useState<number>(initialActiveStep);

  const StepComponent = [ChangeDomainName, ConfigureSSL, UpdateDns][activeStep - 1];

  return (
    <BaseDialog
      open
      title={
        <Stack direction="row" gap={4} alignItems="center">
          <RocketShipSolid />
          <Box>
            <Typography variant="h3">{t('ready_to_go_live')}</Typography>
            <Typography>{t('ready_to_go_live_description')}</Typography>
          </Box>
        </Stack>
      }
      content={
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <WizardStep
              activeStep={activeStep}
              step={1}
              title={t('ready_to_go_live_wizard_step_one_title')}
              description={t('ready_to_go_live_wizard_step_one_description')}
            />
            <WizardStep
              activeStep={activeStep}
              step={2}
              title={t('ready_to_go_live_wizard_step_two_title')}
              description={t('ready_to_go_live_wizard_step_two_description')}
            />
            <WizardStep
              activeStep={activeStep}
              step={3}
              title={t('ready_to_go_live_wizard_step_three_title')}
              description={t('ready_to_go_live_wizard_step_three_description')}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <StepComponent siteDetails={siteDetails} setActiveStep={setActiveStep} />
          </Grid>
        </Grid>
      }
    />
  );
}

export default function ReadyToGoLiveCard({ siteDetails }: { readonly siteDetails?: SiteDetail }) {
  const { sslStatus, showGoLive } = getMainDomainInfo(siteDetails);

  const [open, setOpen] = React.useState<boolean>(!!sslStatus);

  const { t } = useTranslation();

  if (!siteDetails || (siteDetails && siteDetails.production) || showGoLive === 0) {
    return null;
  }

  const openText = sslStatus ? 'Open' : 'Get Started';

  return (
    <Card>
      <CardContent sx={{ padding: 3 }}>
        <Stack direction="row" gap={3} justifyContent="space-between" alignItems="center">
          <Stack direction="row" gap={4} alignItems="center">
            <RocketShipSolid />
            <Box>
              <Typography variant="h3">{t('ready_to_go_live')}</Typography>
              <Typography>{t('ready_to_go_live_description')}</Typography>
            </Box>
          </Stack>
          <Button
            color="primary"
            onClick={() => setOpen(!open)}
            variant={open ? 'outlined' : 'contained'}
          >
            {open ? t('close') : openText}
          </Button>
        </Stack>
      </CardContent>
      {open ? <ReadyToGoLiveDialog siteDetails={siteDetails} /> : null}
    </Card>
  );
}
