import { BoxProps, MenuItem, Typography, ListItemIcon, ListItemText } from '@mui/material';
import { IconWrapper } from '../base/IconWrapper';

type IconMenuItemProps = {
  readonly icon: React.ReactNode;
  readonly title: string;
  readonly onClick: () => void;
  readonly color?: BoxProps['color'];
};

export default function IconMenuItem({ icon, title, onClick, color }: IconMenuItemProps) {
  return (
    <MenuItem onClick={onClick}>
      <ListItemIcon>
        <IconWrapper size="s" color={color ?? 'greys.400'}>
          {icon}
        </IconWrapper>
      </ListItemIcon>
      <ListItemText>
        <Typography variant="body2" component="span" color={color ?? 'greys.900'}>
          {title}
        </Typography>
      </ListItemText>
    </MenuItem>
  );
}
