import { Box } from '@mui/material';
import { SiteActivityCard } from '../SiteActivityCard';
import { useTranslation } from 'react-i18next';
import { useSiteBandwidthTopUsage } from 'api/siteMetrics';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { ReportingListCard } from './ReportingListCard';
import { getSpacing } from 'component/new_design/theme/palettes/utils';
import { ReportingCountries } from './ReportingCountries';
import { getCountryName } from 'utils/country';

const REPORTING_CARDS = [
  'topASNs',
  'topBrowsers',
  'topCacheStatuses',
  'topClientIPs',
  'topColoCodes',
  'topDeviceTypes',
  'topEdgeStatusCodes',
  'topHTTPProtocolVersions',
  'topHosts',
  'topOSs',
  'topOriginStatusCodes',
  'topPaths',
  'topReferers',
  'topUserAgents',
  'topXRequestedWith',
] as const;

type CardType = 'countries' | (typeof REPORTING_CARDS)[number];

// TODO replace with generated API type when available
export type ReportingListItem = {
  metric: string;
  formattedMetric: string;
  count: number;
  countPercentage: number;
};

export const ReportingOverview = () => {
  const { t } = useTranslation();

  const { siteId = '' } = useParams<{ siteId: string }>();

  // TODO - this state is duplicated in SiteActivityCard and sync'd via useEffect
  // refactor when we have a final design for placement of duration select
  const [duration, setDuration] = useState<string>('24h');

  const siteBandwidthTopUsage = useSiteBandwidthTopUsage({
    id: siteId,
    duration,
  });

  const getDataForCard = (card: CardType): ReportingListItem[] => {
    const total =
      Number(siteBandwidthTopUsage.data?.data.data.viewer.zones[0].total[0]?.count) || 0;
    const rawCardData = siteBandwidthTopUsage.data?.data.data.viewer.zones[0][card];
    if (!rawCardData) {
      return [];
    }

    return rawCardData
      .map(item => ({
        metric: item.dimensions.metric,
        formattedMetric:
          card === 'countries'
            ? getCountryName(item.dimensions.metric)
            : item.dimensions.description || item.dimensions.metric,
        count: item.count,
        countPercentage: (item.count / total) * 100,
      }))
      .sort((a, b) => b.count - a.count);
  };

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: ['1fr', '1fr 1fr'],
        gridRowGap: 'var(--dashboard-gap)',
        gridColumnGap: getSpacing(4),
      }}
    >
      <Box sx={{ gridColumn: '1 / -1' }}>
        <SiteActivityCard title={t('overview')} onDurationChange={setDuration} />
      </Box>
      <Box sx={{ gridColumn: '1 / -1' }}>
        <ReportingCountries data={getDataForCard('countries')} />
      </Box>
      {REPORTING_CARDS.map(card => (
        <ReportingListCard
          key={card}
          title={t(`overview_reporting.${card}`)}
          data={getDataForCard(card)}
        />
      ))}
    </Box>
  );
};
