import { Skeleton, TableCell, TableRow } from '@mui/material';
import { TableColumnType, TableRowType } from './types';
import { actionsColumn } from './TableRowActions';
import { TableRowWrapper } from './TableRowWrapper';

interface Props<T extends TableRowType> {
  readonly columns: TableColumnType<T>[];
  readonly hasActionsColumn: boolean;
  readonly perPage: number;
}

export const TableSkeletonRows = <T extends TableRowType>({
  columns,
  perPage,
  hasActionsColumn,
}: Props<T>) => {
  const displayedColumns = hasActionsColumn ? [...columns, actionsColumn] : columns;

  const defaultWidths = [70, 65, 80, 75, 70, 60, 50];

  const getDefaultWidth = (columnIndex: number, rowIndex: number) => {
    return `${defaultWidths[(rowIndex + columnIndex) % defaultWidths.length]}%`;
  };

  return Array.from({ length: perPage }).map((_, rowIndex) => (
    <TableRowWrapper key={rowIndex}>
      <TableRow sx={{ opacity: 1 - rowIndex / perPage }} component="div" role="row">
        {displayedColumns.map((column, columnIndex) => (
          <TableCell key={column.label} align={column.align || 'left'} component="div" role="cell">
            <Skeleton width={column.minWidth || getDefaultWidth(columnIndex, rowIndex)} />
          </TableCell>
        ))}
      </TableRow>
    </TableRowWrapper>
  ));
};
