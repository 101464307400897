import { ChangeEvent, useCallback, useEffect, useMemo, useRef } from 'react';
import { debounce } from 'utils/debounce';

const DEBOUNCE_DELAY = 200;

export const useDebouncedInputProps = (
  value: string,
  onChange: (value: string) => void,
  debounceDelay = DEBOUNCE_DELAY
) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const debouncedOnChange = useMemo(
    () => debounce(onChange, debounceDelay),
    [onChange, debounceDelay]
  );

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => debouncedOnChange(e.target.value || ''),
    [debouncedOnChange]
  );

  useEffect(() => {
    if (inputRef.current && !debouncedOnChange.isPending()) {
      inputRef.current.value = value;
    }
  }, [value, debouncedOnChange]);

  return { ref: inputRef, onChange: handleChange };
};
