import { FilledInput, FilledInputProps, InputAdornment } from '@mui/material';
import { useTranslation } from 'react-i18next';
import SearchBig from 'component/new_design/icons/SearchBig.svg?react';

export const SearchInput = ({ sx, placeholder, ...rest }: FilledInputProps) => {
  const { t } = useTranslation();
  return (
    <FilledInput
      startAdornment={
        <InputAdornment position="start">
          <SearchBig />
        </InputAdornment>
      }
      {...rest}
      sx={{
        width: [150, 200, 250, 150, 220],
        ...sx,
      }}
      placeholder={placeholder || t('search')}
    />
  );
};
