import { useTheme } from '@mui/material';

export default function FileSettingsDuotone({ selected }: { readonly selected: boolean }) {
  const theme = useTheme();

  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.5003 2.70114V0.834461C11.3606 0.833272 11.2122 0.833315 11.0531 0.833361L9.29799 0.833379C8.39483 0.833371 7.66638 0.833364 7.07648 0.881561C6.4691 0.931186 5.93561 1.03603 5.44203 1.28752C4.65803 1.68699 4.02061 2.32441 3.62113 3.10842C3.36964 3.60199 3.2648 4.13549 3.21518 4.74286C3.16698 5.33273 3.16698 6.06117 3.16699 6.96427V13.0357C3.16698 13.9388 3.16698 14.6673 3.21518 15.2572C3.2648 15.8646 3.36964 16.3981 3.62113 16.8916C4.02061 17.6756 4.65803 18.3131 5.44203 18.7126C5.93561 18.9641 6.4691 19.0689 7.07648 19.1186C7.66635 19.1667 8.39486 19.1667 9.29799 19.1667H11.5851C11.1134 18.717 10.8174 18.0847 10.8101 17.383L10.8063 17.0134L10.5476 16.7493C9.59566 15.7774 9.59566 14.2226 10.5476 13.2507L10.8063 12.9866L10.8101 12.617C10.8242 11.2566 11.9236 10.1572 13.284 10.1431L13.6536 10.1393L13.9177 9.88066C14.8896 8.92868 16.4444 8.92868 17.4163 9.88066L17.6804 10.1393L18.05 10.1431C18.0892 10.1435 18.1282 10.1448 18.167 10.147V7.94757C18.1671 7.78834 18.1671 7.63989 18.1659 7.50006H16.2992C15.6284 7.50007 15.0748 7.50008 14.6238 7.46324C14.1554 7.42496 13.7248 7.34283 13.3203 7.13675C12.6932 6.81717 12.1832 6.30724 11.8637 5.68003C11.6576 5.27559 11.5754 4.84499 11.5372 4.37659C11.5003 3.92559 11.5003 3.37195 11.5003 2.70114Z"
        fill={selected ? theme.palette.common.white : theme.palette.primary.main}
      />
      <path
        d="M15.7393 15C15.7321 15.017 15.725 15.034 15.7181 15.0512C15.701 15.058 15.684 15.0651 15.667 15.0723C15.65 15.0651 15.633 15.058 15.6159 15.0512C15.609 15.034 15.6019 15.017 15.5947 15C15.6019 14.983 15.609 14.966 15.6159 14.9489C15.633 14.942 15.65 14.9349 15.667 14.9277C15.684 14.9349 15.701 14.942 15.7181 14.9489C15.725 14.966 15.7321 14.983 15.7393 15Z"
        fill={theme.palette.primary.main}
      />
      <path
        d="M13.1667 1.06851V2.66645C13.1667 3.38026 13.1673 3.8655 13.198 4.24058C13.2278 4.60593 13.2819 4.79276 13.3483 4.9231C13.5081 5.2367 13.7631 5.49167 14.0767 5.65145C14.207 5.71786 14.3938 5.77197 14.7592 5.80182C15.1342 5.83246 15.6195 5.83311 16.3333 5.83311H17.9313C17.8338 5.55539 17.7069 5.2884 17.5527 5.03673C17.2942 4.61488 16.9398 4.26064 16.4277 3.74887L15.2497 2.57092C14.7385 2.0595 14.3846 1.7054 13.963 1.4471C13.7114 1.29288 13.4444 1.16605 13.1667 1.06851Z"
        fill={theme.palette.primary.light}
      />
      <path
        d="M15.6667 14.1665C15.2064 14.1665 14.8333 14.5395 14.8333 14.9998C14.8333 15.46 15.2064 15.8331 15.6667 15.8331H15.675C16.1352 15.8331 16.5083 15.46 16.5083 14.9998C16.5083 14.5395 16.1352 14.1665 15.675 14.1665H15.6667Z"
        fill={theme.palette.primary.light}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.2498 11.0711C15.9258 10.7538 15.4075 10.7538 15.0836 11.0711L14.3407 11.7987L13.301 11.8095C12.8475 11.8142 12.4811 12.1806 12.4764 12.6341L12.4656 13.6738L11.738 14.4167C11.4207 14.7407 11.4207 15.2589 11.738 15.5829L12.4656 16.3257L12.4764 17.3655C12.4811 17.8189 12.8475 18.1854 13.301 18.1901L14.3407 18.2009L15.0836 18.9285C15.4075 19.2458 15.9258 19.2458 16.2498 18.9285L16.9926 18.2009L18.0323 18.1901C18.4858 18.1854 18.8523 17.8189 18.857 17.3655L18.8678 16.3257L19.5953 15.5829C19.9127 15.2589 19.9127 14.7407 19.5953 14.4167L18.8678 13.6738L18.857 12.6341C18.8523 12.1806 18.4858 11.8142 18.0323 11.8095L16.9926 11.7987L16.2498 11.0711ZM15.2676 13.2238L15.6667 12.8329L16.0658 13.2238C16.2194 13.3743 16.4252 13.4596 16.6402 13.4618L17.1989 13.4676L17.2047 14.0262C17.2069 14.2412 17.2922 14.4471 17.4426 14.6007L17.8335 14.9998L17.4426 15.3989C17.2922 15.5525 17.2069 15.7583 17.2047 15.9734L17.1989 16.532L16.6402 16.5378C16.4252 16.54 16.2194 16.6253 16.0658 16.7757L15.6667 17.1667L15.2676 16.7757C15.1139 16.6253 14.9081 16.54 14.6931 16.5378L14.1345 16.532L14.1287 15.9734C14.1264 15.7583 14.0412 15.5525 13.8907 15.3989L13.4998 14.9998L13.8907 14.6007C14.0412 14.4471 14.1264 14.2412 14.1287 14.0262L14.1345 13.4676L14.6931 13.4618C14.9081 13.4596 15.1139 13.3743 15.2676 13.2238Z"
        fill={theme.palette.primary.light}
      />
    </svg>
  );
}
