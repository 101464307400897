import { Button, Typography } from '@mui/material';
import { FileManagerFile } from 'api/site';
import DirectoryIcon from 'component/new_design/icons/Folder.svg?react';
import FileIcon from 'component/new_design/icons/File.svg?react';
import { IconWrapper } from 'component/new_design/base/IconWrapper';

interface FileNameCellProps {
  readonly file: FileManagerFile;
  readonly onClick: () => void;
}

export const FileNameCell = ({ file, onClick }: FileNameCellProps) => {
  const FileOrDirIcon = file.type === 'dir' ? DirectoryIcon : FileIcon;

  return (
    <Button
      onClick={onClick}
      variant="text"
      startIcon={
        <IconWrapper size="s" color="greys.500">
          <FileOrDirIcon />
        </IconWrapper>
      }
    >
      <Typography variant="body2" color="greys.900" ml={1}>
        {file.name}
      </Typography>
    </Button>
  );
};
