import { Link, Stack, Typography } from '@mui/material';
import ChevronLeft from '../icons/ChevronLeft.svg?react';
import { useTranslation } from 'react-i18next';

export default function HistoryBackButton() {
  const { t } = useTranslation();

  return (
    <Link component="button" onClick={() => window.history.back()}>
      <Stack direction="row">
        <ChevronLeft />
        <Typography>{t('back')}</Typography>
      </Stack>
    </Link>
  );
}
