import { Box, Button, Card, CardHeader, Stack, useTheme } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReportingList } from './ReportingList';
import { ReportingListDialog } from './ReportingListDialog';
import { ResponsiveChoropleth } from '@nivo/geo';
import { features } from './world-map-a-2.json';
import { abbreaviateNumber } from 'utils/number';
import { getSpacing } from 'component/new_design/theme/palettes/utils';
import { Flag } from 'component/new_design/base/Flag';
import { ReportingListItem } from './ReportingOverview';

interface ReportingCountriesProps {
  readonly data: ReportingListItem[];
}

const LIST_ITEM_COUNT = 7;

const renderCountryLabel = (item: ReportingListItem) => (
  <Stack direction="row" alignItems="center" gap={2}>
    <Flag code={item.metric} />
    {item.formattedMetric}
  </Stack>
);

export const ReportingCountries = ({ data }: ReportingCountriesProps) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const { t } = useTranslation();

  const theme = useTheme();

  const title = t('overview_reporting.countries');

  const needsSeeAllDialog = data.length > LIST_ITEM_COUNT;
  const cardData = needsSeeAllDialog ? data.slice(0, LIST_ITEM_COUNT) : data;

  const colors = ([200, 400, 600, 800] as const).map(color => theme.palette.blues[color]);

  const max = data.reduce((max, { count }) => Math.max(max, count), 0);
  const maxRounded = max + colors.length - (max % colors.length);

  const mapData = data.map(({ metric, count }) => ({
    id: metric,
    value: count,
  }));

  return (
    <>
      <Card>
        <CardHeader
          title={title}
          action={
            needsSeeAllDialog ? (
              <Button variant="contained" color="secondary" onClick={() => setDialogOpen(true)}>
                {t('see_all')}
              </Button>
            ) : null
          }
        />
        <Box
          sx={{
            margin: getSpacing(4, 2, 2),
            height: [
              undefined,
              undefined,
              `calc(var(--reporting-list-item-height) * ${LIST_ITEM_COUNT})`,
            ],
            display: 'grid',
            gridTemplateColumns: ['1fr', '1fr', '1fr 1fr'],
            gap: 4,
            svg: { height: `calc(var(--reporting-list-item-height) * ${LIST_ITEM_COUNT})` },
            path: { transition: 'fill 0.3s' },
            g: { transition: 'opacity 0.3s' },
          }}
        >
          <div>
            <ResponsiveChoropleth
              features={features}
              data={mapData}
              value="value"
              projectionType="naturalEarth1"
              colors={colors}
              projectionScale={100}
              domain={[0, maxRounded]}
              unknownColor={data.length ? theme.palette.blues[50] : theme.palette.greys[50]}
              label="properties.name"
              projectionTranslation={[0.5, 0.5]}
              valueFormat={abbreaviateNumber}
              borderWidth={0.5}
              borderColor={theme.palette.common.white}
              legends={[
                {
                  anchor: 'bottom',
                  direction: 'row',
                  itemsSpacing: 10,
                  itemWidth: 80,
                  itemHeight: 20,
                  itemOpacity: data.length ? 1 : 0,
                  translateY: -20,
                  itemDirection: 'left-to-right',
                  itemTextColor: theme.palette.greys[500],
                  symbolSize: 8,
                },
              ]}
            />
          </div>
          <ReportingList data={cardData} renderLabel={renderCountryLabel} />
        </Box>
      </Card>
      {dialogOpen ? (
        <ReportingListDialog
          title={title}
          data={data}
          renderLabel={renderCountryLabel}
          onClose={() => setDialogOpen(false)}
        />
      ) : null}
    </>
  );
};
