import { Card, Box, Stack, Typography } from '@mui/material';
import { IconWrapper } from '../base/IconWrapper';

export default function ActionCard({
  actions,
  icon,
  paletteColor,
  title,
  description,
  leftContent,
}: {
  readonly actions?: React.ReactNode;
  readonly icon?: React.ReactNode;
  readonly paletteColor: 'greys' | 'oranges';
  readonly title?: string;
  readonly description?: string;
  readonly leftContent?: React.ReactNode;
}) {
  return (
    <Card
      sx={theme => ({
        display: 'flex',
        minHeight: '80px',
        color: theme.palette[paletteColor][900],
        backgroundColor:
          paletteColor === 'greys' ? theme.palette.common.white : theme.palette[paletteColor][100],
        p: 4,
        gap: 4,
        flexDirection: [actions ? 'column' : 'row', 'row'],
        alignItems: 'center',
        justifyContent: 'space-between',
      })}
    >
      <Stack
        gap={4}
        direction="row"
        sx={theme => ({
          alignItems: 'center',
          [theme.breakpoints.down('sm')]: {
            alignItems: actions && icon ? 'flex-start' : 'center',
          },
          color: theme.palette[paletteColor][800],
        })}
      >
        {icon ? (
          <IconWrapper size="m" color={`${paletteColor}.700`}>
            {icon}
          </IconWrapper>
        ) : null}
        {leftContent}
        {title || description ? (
          <Stack sx={{ flexGrow: 1 }}>
            {title ? (
              <Typography variant="body1" fontWeight="600" color={`${paletteColor}.900`}>
                {title}
              </Typography>
            ) : null}
            {description ? (
              <Typography variant="body2" color={`${paletteColor}.800`}>
                {description}
              </Typography>
            ) : null}
          </Stack>
        ) : null}
      </Stack>
      {actions ? (
        <Box
          sx={theme => ({
            width: 'fit-content',
            [theme.breakpoints.down('sm')]: {
              width: '100%',
            },
          })}
        >
          {actions}
        </Box>
      ) : null}
    </Card>
  );
}
