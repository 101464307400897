import { Typography } from '@mui/material';
import { ReactNode } from 'react';

interface LabelProps {
  readonly htmlFor: string;
  readonly children: ReactNode;
}

export const Label = ({ children, htmlFor }: LabelProps) => (
  <Typography variant="body2" color="greys.500" pb={2} component="label" htmlFor={htmlFor}>
    {children}
  </Typography>
);
