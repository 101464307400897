export const debounce = <Args extends unknown[]>(fn: (...args: Args) => void, timeout = 1000) => {
  let timer: any;
  const f = (...args: Args) => {
    timer && clearTimeout(timer);
    timer = setTimeout(() => {
      fn(...args);
      clearTimeout(timer);
    }, timeout);
  };
  f.cancel = () => {
    clearTimeout(timer);
  };
  f.isPending = () => !!timer;
  return f;
};
