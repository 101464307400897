import { useTranslation } from 'react-i18next';
import { Event } from 'api/siteMetrics';
import { Box, Stack } from '@mui/material';
import { Gravatar } from 'component/new_design/base/Gravatar';

interface AuthorCellProps {
  readonly event: Event;
}

export const AuthorCell = ({ event }: AuthorCellProps) => {
  const { t } = useTranslation();

  return (
    <Stack direction="row" gap={2} alignItems="center">
      <Gravatar name={event.display_name!} email={event.user_email!} />
      <Stack>
        <span>{event.display_name ? event.display_name : t('unknown')}</span>
        <Box sx={{ textTransform: 'capitalize' }}>{event.roles}</Box>
      </Stack>
    </Stack>
  );
};
