import {
  Select as MuiSelect,
  SelectProps as MuiSelectProps,
  CircularProgress,
  MenuItem,
  ListSubheader,
  Box,
  SelectChangeEvent,
} from '@mui/material';
import { ReactNode } from 'react';
import ChevronSortVertical from 'component/new_design/icons/ChevronSortVertical.svg?react';
import CheckCircle from 'component/new_design/icons/CheckCircle.svg?react';

export type SelectOption<T> =
  | {
      readonly value: T;
      readonly label: ReactNode;
      readonly type?: 'option';
    }
  | {
      readonly type: 'subheader';
      readonly label: string;
    };

export interface SelectProps<T>
  extends Omit<MuiSelectProps<T>, 'IconComponent' | 'renderValue' | 'children' | 'onChange'> {
  readonly loading?: boolean;
  readonly options: Array<SelectOption<T>>;
  readonly enableEmptyOption?: boolean;
  readonly emptyOptionLabel?: ReactNode;
  readonly onChange: (value: T) => void;
}

export const Select = <T extends string | number>({
  loading,
  options,
  enableEmptyOption,
  emptyOptionLabel = '\u2014', // em dash
  onChange,
  ...rest
}: SelectProps<T>) => {
  const handleChange = (event: SelectChangeEvent<any>) => {
    onChange(event.target.value as T);
  };

  const renderValue = (value: T) =>
    options.find(item => item.type !== 'subheader' && item.value === value)?.label ??
    emptyOptionLabel;

  const icon = loading ? () => <CircularProgress size={14} /> : ChevronSortVertical;

  return (
    <MuiSelect
      {...rest}
      onChange={handleChange}
      disabled={loading || rest.disabled}
      IconComponent={icon}
      renderValue={renderValue}
    >
      {enableEmptyOption ? <MenuItem value={undefined}>{emptyOptionLabel}</MenuItem> : null}
      {options.map(item =>
        item.type === 'subheader' ? (
          <ListSubheader key={item.label}>{item.label}</ListSubheader>
        ) : (
          <MenuItem value={item.value} key={item.value}>
            {item.label}
            {item.value === rest.value ? (
              <Box sx={{ ml: 'auto', display: 'grid', placeItems: 'center' }}>
                <CheckCircle />
              </Box>
            ) : null}
          </MenuItem>
        )
      )}
    </MuiSelect>
  );
};
