import { useTranslation } from 'react-i18next';
import ChevronRight from 'component/new_design/icons/ChevronRight.svg?react';
import Plus from 'component/new_design/icons/Plus.svg?react';
import { ACCESS_LOG_FILTER_KEYS, AccessLogFilterKey, useFilterLabels } from './common';
import { DropdownMenu } from 'component/new_design/base/DropdownMenu';

const filterMenuItems = ACCESS_LOG_FILTER_KEYS.filter(key => key !== 'duration');

interface AccessLogFilterMenuProps {
  readonly onFilterOpen: (filter: AccessLogFilterKey) => void;
}

export const AccessLogFilterMenu = ({ onFilterOpen }: AccessLogFilterMenuProps) => {
  const { t } = useTranslation();
  const { getLabel } = useFilterLabels();

  return (
    <DropdownMenu
      buttonProps={{
        variant: 'contained',
        color: 'primary',
        startIcon: <Plus />,
        children: t('add_filter'),
      }}
      actions={filterMenuItems.map(filter => ({
        label: getLabel(filter),
        onClick: () => onFilterOpen(filter),
        endIcon: <ChevronRight />,
      }))}
    />
  );
};
