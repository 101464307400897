import { useSdk } from './useSdk';

export const useDialogProps = () => {
  const sdk = useSdk();

  return {
    hideBackdrop: Boolean(sdk),
    container: sdk ? window.document.getElementsByTagName('main')[0] : window.document.body,
    style: sdk ? { position: 'absolute' as const } : {},
  };
};
