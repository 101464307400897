import { Box } from '@mui/material';
import { ErrorBoundary } from 'react-error-boundary';
import FlagPack from 'react-flagpack';

interface FlagProps {
  readonly code: string;
  readonly size?: 's' | 'm';
}

const sizeMap = {
  s: '16px',
  m: '24px',
};

export const Flag = ({ code, size }: FlagProps) => {
  const flagCode = code === 'GB' ? 'GB-UKM' : code;

  return (
    <Box
      sx={{
        width: sizeMap[size ?? 'm'],
        height: sizeMap[size ?? 'm'],
        backgroundColor: 'greys.200',
        borderRadius: sizeMap[size ?? 'm'],
        'div.flag.size-l': {
          objectFit: 'fill',
          width: '100%',
          height: '100%',
          borderRadius: sizeMap[size ?? 'm'],
        },
      }}
    >
      <ErrorBoundary fallback={<span />}>
        <FlagPack code={flagCode} />
      </ErrorBoundary>
    </Box>
  );
};
