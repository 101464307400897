import { ReactNode } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { getSpacing } from 'component/new_design/theme/palettes/utils';
import { useTranslation } from 'react-i18next';
import { abbreaviateNumber } from 'utils/number';
import BarChart from 'component/new_design/icons/BarChart.svg?react';
import { ReportingListItem } from './ReportingOverview';
import { EmptyState } from 'component/new_design/base/EmptyState';

interface ReportingListProps {
  readonly data: ReportingListItem[];
  readonly renderLabel?: (item: ReportingListItem) => ReactNode;
  readonly variant?: 'card' | 'dialog';
}

export const ReportingList = ({ data, renderLabel, variant = 'card' }: ReportingListProps) => {
  const { t } = useTranslation();

  return data.length ? (
    <Stack component="ul">
      {data.map(item => (
        <Stack
          key={item.metric}
          component="li"
          sx={{ position: 'relative', height: 'var(--reporting-list-item-height)' }}
        >
          <Box
            sx={{
              position: 'absolute',
              left: getSpacing(1),
              top: `calc(${getSpacing(1.5)} - 1px)`,
              bottom: `calc(${getSpacing(1.5)} + 2px)`,
              right: getSpacing(1),
              backgroundColor: 'greys.50',
              borderRadius: 'var(--border-radius-s)',
            }}
            style={{ right: `calc(100% - ${item.countPercentage}% + ${getSpacing(1)})` }}
          />
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              position: 'absolute',
              top: 0,
              bottom: '1px',
              left: getSpacing(3),
              right: getSpacing(3),
              'li:not(:last-of-type) &': {
                borderBottom: '1px solid',
                borderColor: 'greys.50',
              },
              gap: 4,
              minWidth: 0,
            }}
          >
            <Typography
              variant="body2"
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis"
              fontWeight={variant === 'dialog' ? 600 : 400}
            >
              {renderLabel?.(item) || item.formattedMetric || '-'}
            </Typography>
            <Typography variant="body2" color="greys.500" whiteSpace="nowrap">
              {`${abbreaviateNumber(item.count)} (${item.countPercentage.toFixed(0)}%)`}
            </Typography>
          </Stack>
        </Stack>
      ))}
    </Stack>
  ) : (
    <EmptyState title={t('No data yet')} icon={<BarChart />} />
  );
};
