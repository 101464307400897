import React from 'react';
import { Button, Stack, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { InviteUserData, useUserSiteInvite } from 'api/auth';
import { useParams } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { SiteDetail } from 'api/site';
import Plus from '../icons/Plus.svg?react';
import { FormDialog } from '../base/dialogs/FormDialog';
import { TextField } from '../base/forms/TextField';

export function AddSiteUser({ siteDetails }: { readonly siteDetails?: SiteDetail }) {
  const [showAddDialog, setShowAddDialog] = React.useState<boolean>(false);
  const { siteId = '' } = useParams<{ siteId: string }>();
  const { mutateAsync, isPending } = useUserSiteInvite(siteId);
  const methods = useForm<InviteUserData>({
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
    },
  });
  const { reset } = methods;
  const { t } = useTranslation();
  const onSubmit = async (data: InviteUserData) => {
    await mutateAsync(data);
    reset();
    setShowAddDialog(false);
  };

  function handleClose() {
    reset();
    setShowAddDialog(false);
  }

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          setShowAddDialog(true);
        }}
        startIcon={<Plus />}
      >
        {t('invite_site_user')}
      </Button>
      <FormDialog
        form={
          <Stack gap={4}>
            <Typography variant="body2">
              <Trans
                i18nKey="add_site_user_description"
                values={{ siteName: siteDetails?.domain ?? '' }}
              />
            </Typography>
            <TextField
              name="firstName"
              disabled={isPending}
              fullWidth
              label={t('first_name')}
              rules={{ required: true }}
            />

            <TextField
              name="lastName"
              disabled={isPending}
              fullWidth
              label={t('last_name')}
              rules={{ required: true }}
            />

            <TextField
              name="email"
              disabled={isPending}
              fullWidth
              label={t('email')}
              rules={{ required: true }}
            />
          </Stack>
        }
        methods={methods}
        onSubmit={onSubmit}
        onClose={handleClose}
        open={showAddDialog}
        title={t('invite_site_user')}
        submitText={t('add_user')}
      />
    </>
  );
}
