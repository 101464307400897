import { Box, Stack } from '@mui/material';
import { CompactTabs } from 'component/new_design/base/CompactTabs';
import { linkHelper } from 'linkHelper';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

interface ReportingProps {
  readonly siteId: string;
}

const Reporting = ({ siteId }: ReportingProps) => {
  const { t } = useTranslation();

  return (
    <Stack gap={6}>
      <Box sx={{ alignSelf: 'flex-start' }}>
        <CompactTabs
          label={t('reporting_page')}
          tabs={[
            {
              title: t('overview'),
              href: linkHelper.newDesign.sites.siteDetails.reporting.overview.getLink({ siteId }),
            },
            {
              title: t('delivery'),
              href: linkHelper.newDesign.sites.siteDetails.reporting.delivery.getLink({ siteId }),
            },
          ]}
        />
      </Box>
      <Outlet />
    </Stack>
  );
};

export default Reporting;
