import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

export const ACCESS_LOG_FILTER_KEYS = [
  'duration',
  'cache_status',
  'ip',
  'uri',
  'ray_id',
  'device_type',
  'status_code',
] as const;

export type AccessLogFilterKey = (typeof ACCESS_LOG_FILTER_KEYS)[number];

export const useFilterLabels = () => {
  const { t } = useTranslation();

  const getLabel = useCallback(
    (key: (typeof ACCESS_LOG_FILTER_KEYS)[number]) => {
      switch (key) {
        case 'cache_status':
          return t('cache_status');
        case 'ip':
          return t('ip_address');
        case 'uri':
          return 'URI';
        case 'ray_id':
          return 'Ray ID';
        case 'device_type':
          return t('device_type');
        case 'status_code':
          return t('status_code');
        case 'duration':
          return t('duration');
        default:
          return '-';
      }
    },
    [t]
  );

  return { getLabel };
};
