import { useState } from 'react';
import { Box, Typography, Button, Stack, Menu, MenuItem, Skeleton } from '@mui/material';
import { SiteDetail, SitePaginationState, useSites, useUserSites } from 'api/site';
import { useNavigate, useLocation } from 'react-router-dom';
import ChevronSortVertical from '../icons/ChevronSortVertical.svg?react';
import CheckCircle from '../icons/CheckCircle.svg?react';
import { IconWrapper } from '../base/IconWrapper';

export function SiteSelectorMenu({
  siteDetails,
  isWpSiteControl,
}: {
  readonly siteDetails: SiteDetail | undefined;
  readonly isWpSiteControl: boolean;
}) {
  const fetchParams: SitePaginationState = {
    perPage: 100,
    activePageNumber: 1,
    filter: '',
    sortAttr: 'domain',
    sortOrder: 'asc',
  };
  const sitesData = useSites(fetchParams, { enabled: !isWpSiteControl });
  const userSitesData = useUserSites(fetchParams, { enabled: isWpSiteControl });
  const { data, status } = isWpSiteControl ? userSitesData : sitesData;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const isLoading = sitesData.isLoading || userSitesData.isLoading;

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const renderMenuItems = () => {
    return data?.data.result?.map(site => {
      const selected = siteDetails?.id === site.id;
      return (
        <MenuItem
          key={site.id}
          onClick={() => {
            navigate(pathname.replace(String(siteDetails?.id ?? ''), String(site.id)));
            setAnchorEl(null);
          }}
          selected={selected}
        >
          <Stack direction="row" width="100%">
            <Stack>
              <Typography variant="body2" fontWeight="600">
                {site.domain}
              </Typography>
              {site.label ? (
                <Typography fontWeight="400" color="greys.500" variant="caption">
                  {site.label}
                </Typography>
              ) : null}
            </Stack>
            {selected ? (
              <Box sx={{ ml: 'auto', display: 'grid', placeItems: 'center' }}>
                <CheckCircle />
              </Box>
            ) : null}
          </Stack>
        </MenuItem>
      );
    });
  };

  if (!siteDetails) {
    return (
      <Typography variant="h2">
        <Skeleton variant="rectangular" height="2rem" width="200px" />
      </Typography>
    );
  }

  return (
    <>
      {!isLoading && data?.data.result?.length ? (
        <Button
          id="sites-dropdown-button"
          aria-controls={open ? 'sites-dropdown-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          disabled={isLoading}
        >
          <Stack direction="row" alignItems="center" gap={1}>
            <Typography color={open ? 'primary.main' : 'greys.900'} variant="h2">
              {siteDetails?.domain ?? ''}
            </Typography>
            <IconWrapper size="m" color={open ? 'primary.main' : 'greys.900'}>
              <ChevronSortVertical />
            </IconWrapper>
          </Stack>
        </Button>
      ) : (
        <Typography variant="h2">{siteDetails?.domain}</Typography>
      )}
      <Menu
        id="sites-dropdown-menu"
        aria-labelledby="sites-dropdown-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        slotProps={{
          paper: {
            sx: theme => ({
              minWidth: '290px',
            }),
          },
        }}
        sx={{
          marginTop: '50px',
        }}
      >
        {renderMenuItems()}
      </Menu>
    </>
  );
}
