import { MenuItem, SelectChangeEvent } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Select } from '../base/forms/Select';

interface DurationSelectProps {
  readonly value?: string;
  readonly onChange: (duration: string) => void;
}

export const DurationSelect = ({ value = '1h', onChange }: DurationSelectProps) => {
  const { t } = useTranslation();

  const durationOptions = [
    { value: '1h', label: t('last_1_hour') },
    { value: '6h', label: t('last_6_hours') },
    { value: '12h', label: t('last_12_hours') },
    { value: '24h', label: t('last_24_hours') },
    { value: '72h', label: t('last_72_hours') },
    { value: '7d', label: t('last_7_days') },
    { value: '30d', label: t('last_30_days') },
  ];

  const handleChange = (duration: string) => {
    onChange(duration);
  };

  return <Select value={value} onChange={handleChange} options={durationOptions} />;
};
