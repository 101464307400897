import { PaginatedListState } from 'component/hooks/usePaginatedListState';
import { ACCESS_LOG_FILTER_KEYS, useFilterLabels } from './common';
import { Stack } from '@mui/material';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { SelectField } from 'component/new_design/base/forms/SelectField';
import { TextField } from 'component/new_design/base/forms/TextField';
import { FormDialog } from 'component/new_design/base/dialogs/FormDialog';

type FilterKey = (typeof ACCESS_LOG_FILTER_KEYS)[number];

interface AccessLogFilterDialogProps {
  readonly state: PaginatedListState<FilterKey>;
  readonly filterKey: FilterKey;
  readonly onClose: () => void;
}

type FormValues = {
  value: string;
};

export const AccessLogFilterDialog = ({
  state,
  filterKey,
  onClose,
}: AccessLogFilterDialogProps) => {
  const { t } = useTranslation();
  const { getLabel } = useFilterLabels();

  const filterValue = state.params.filters?.[filterKey];
  const label = getLabel(filterKey);
  const title = `${t(filterValue ? 'change' : 'add')} ${label}`;

  const methods = useForm<FormValues>({
    defaultValues: { value: filterValue || '' },
  });

  const onSubmit = useCallback(
    (data: FormValues) => {
      state.setFilter(filterKey, data.value);
      onClose();
    },
    [state.setFilter, filterKey]
  );

  return (
    <FormDialog
      form={
        <Stack>
          {filterKey === 'device_type' ? (
            <SelectField
              label={label}
              name="value"
              options={[
                { label: t('desktop'), value: 'desktop' },
                { label: t('tablet'), value: 'tablet' },
                { label: t('phone'), value: 'phone' },
              ]}
              rules={{ required: true }}
            />
          ) : (
            <TextField label={label} name="value" rules={{ required: true }} />
          )}
        </Stack>
      }
      methods={methods}
      onClose={onClose}
      onSubmit={onSubmit}
      title={title}
      submitText={t('apply')}
    />
  );
};
