import { Box, Stack } from '@mui/material';
import { CompactTabs } from 'component/new_design/base/CompactTabs';
import { linkHelper } from 'linkHelper';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

interface LogsProps {
  readonly siteId: string;
}

const Logs = ({ siteId }: LogsProps) => {
  const { t } = useTranslation();

  return (
    <Stack gap={6}>
      <Box sx={{ alignSelf: 'flex-start' }}>
        <CompactTabs
          label={t('log_type')}
          tabs={[
            {
              title: t('activity_log'),
              href: linkHelper.newDesign.sites.siteDetails.logs.activityLog.getLink({ siteId }),
            },
            {
              title: t('access_log'),
              href: linkHelper.newDesign.sites.siteDetails.logs.accessLog.getLink({ siteId }),
            },
          ]}
        />
      </Box>
      <Outlet />
    </Stack>
  );
};

export default Logs;
