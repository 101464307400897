import { createTheme, lighten, darken } from '@mui/material';
import { cssVar } from 'utils/css';
import { BASE_SPACING } from '../constants';

const theme = createTheme({
  spacing: BASE_SPACING,
});

export const getContrastText = (color: string) => {
  return theme.palette.getContrastText(color);
};

export const getSpacing = theme.spacing;

export const getPrimaryColors = (
  mode: 'light' | 'dark',
  defaultPrimary: string,
  defaultPrimaryContrast: string,
  defaultPrimaryLight: string
) => {
  const sdkPrimary = cssVar('--primary-new');
  let primary = sdkPrimary;
  let primaryContrast = defaultPrimaryContrast;
  let primaryLight = defaultPrimaryLight;
  if (!sdkPrimary) {
    primary = defaultPrimary;
    primaryContrast = defaultPrimaryContrast;
  } else {
    primaryContrast = getContrastText(primary);
    primaryLight = mode === 'light' ? lighten(primary, 0.5) : darken(primary, 0.5);
  }

  return {
    primary,
    primaryContrast,
    primaryLight,
  };
};
