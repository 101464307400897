import { Box, Container, Typography } from '@mui/material';
import { useGetDrawerWidth } from '../utils/layout';

export default function Page({ children }: { readonly children: React.ReactNode }) {
  const drawerWidth = useGetDrawerWidth();

  return (
    <Box
      component="main"
      id="mainContent"
      sx={{
        width: { sm: `calc(100% - ${drawerWidth})` },
        ml: { sm: `${drawerWidth}` },
        mt: '72px',
        p: 'var(--dashboard-gap)',
      }}
    >
      <Container maxWidth="lg" disableGutters>
        {children}
      </Container>
    </Box>
  );
}
