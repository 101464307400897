import { FormControl, FormHelperText } from '@mui/material';
import { ReactNode, useId } from 'react';
import { RegisterOptions, useController } from 'react-hook-form';
import { Select, SelectProps } from './Select';
import { Label } from './Label';

interface SelectFieldProps<T> extends Omit<SelectProps<T>, 'onChange' | 'onBlur'> {
  readonly name: string;
  readonly label: ReactNode;
  readonly rules?: RegisterOptions;
  readonly helperText?: ReactNode;
}

export const SelectField = <T extends string | number>({
  name,
  label,
  rules,
  ...rest
}: SelectFieldProps<T>) => {
  const id = useId();

  const {
    field: { ref, ...field },
    fieldState: { error },
  } = useController({
    name,
    rules,
    disabled: rest.disabled,
    defaultValue: rest.defaultValue,
  });

  const helperText = error?.message || rest.helperText;

  return (
    <FormControl>
      <Label htmlFor={id}>{label}</Label>
      <Select id={id} inputRef={ref} {...rest} {...field} />
      {helperText ? <FormHelperText>{helperText}</FormHelperText> : null}
    </FormControl>
  );
};
