import { Box, Card, CardContent, CardHeader, Stack, Typography } from '@mui/material';
import { GraphCard } from 'component/base/GraphCard';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { DurationSelect } from '../DurationSelect';
import { useMemo, useState } from 'react';
import { useSiteCacheStatus } from 'api/siteMetrics';

const STATUS_TYPES = [
  'hit',
  'none',
  'dynamic',
  'miss',
  'expired',
  'bypass',
  'revalidated',
] as const;

const statusColors = {
  hit: 'greens.500',
  none: 'greys.500',
  dynamic: 'oranges.500',
  miss: 'reds.500',
  expired: 'blues.500',
  bypass: 'purple',
  revalidated: 'violet',
};

export const ReportingDelivery = () => {
  const { t } = useTranslation();
  const { siteId = '' } = useParams<{ siteId: string }>();

  const [duration, setDuration] = useState('24h');

  const cacheStatus = useSiteCacheStatus({
    id: siteId,
    duration,
  });

  const cardsData = useMemo(() => {
    const totalCount =
      cacheStatus.data?.data.data.viewer.zones[0].cacheStatus.reduce(
        (sum, data) => sum + data.count,
        0
      ) || 0;

    return STATUS_TYPES.map(status => {
      const count =
        cacheStatus.data?.data.data.viewer.zones[0].cacheStatus.find(
          data => data.dimensions.cacheStatus === status
        )?.count || 0;
      return {
        status,
        count,
        percentage: totalCount ? (count / totalCount) * 100 : 0,
        color: statusColors[status],
      };
    });
  }, [cacheStatus.data]);

  const chartData = useMemo(() => {
    if (cacheStatus.isLoading) {
      return [4, 3, 2, 1].map(count => ({ count, color: '' }));
    }
    return cardsData.toSorted((a, b) => b.count - a.count).filter(({ count }) => count > 0);
  }, [cardsData, cacheStatus.isLoading]);

  return (
    <Card>
      <CardHeader
        title={t('cache_status')}
        action={<DurationSelect value={duration} onChange={setDuration} />}
      />
      <CardContent>
        <Stack gap={10}>
          <Box
            sx={{
              display: 'grid',
              gap: 2,
              gridTemplateColumns: ['1fr', 'repeat(2, 1fr)', 'repeat(4, 1fr)'],
            }}
          >
            {cardsData.map(({ status, count, percentage, color }) => (
              <GraphCard
                key={status}
                color={color}
                label={t(status)}
                value={count}
                valuePercentage={percentage}
                loading={cacheStatus.isLoading}
              />
            ))}
          </Box>
          <Box
            sx={{ display: 'grid', gap: 2 }}
            style={{
              gridTemplateColumns: chartData
                .map(({ count }) => `minmax(max-content, ${count}fr)`)
                .join(' '),
            }}
          >
            {chartData.map(({ color, count }) => (
              <Box
                key={`${color}-${count}`}
                sx={{
                  background: cacheStatus.isLoading
                    ? 'linear-gradient(270deg, rgba(237, 232, 232, 0.75) 0%, rgba(237, 232, 232, 0.25) 71.18%)'
                    : undefined,
                  backgroundColor: cacheStatus.isLoading ? undefined : color,
                  color: 'common.white',
                  height: '64px',
                  px: 2.5,
                  borderRadius: 'var(--border-radius-m)',
                  display: 'grid',
                  placeItems: 'center',
                }}
              >
                {cacheStatus.isLoading ? null : (
                  <Typography variant="body2" fontWeight={600}>
                    {count}
                  </Typography>
                )}
              </Box>
            ))}
          </Box>
        </Stack>
      </CardContent>
    </Card>
  );
};
