import { Box } from '@mui/material';
import { PropsWithChildren } from 'react';

export const ExpandedRowWrapper = ({ children }: PropsWithChildren) => (
  <Box
    sx={{
      gridColumn: '1 / -1',
      display: 'grid',
      gridTemplateColumns: 'subgrid',
      px: 3,
      mt: 1,
      borderRadius: 'var(--border-radius-m)',
      backgroundColor: 'greys.100',
    }}
  >
    {children}
  </Box>
);
