import { Box } from '@mui/material';
import { ReactNode } from 'react';

interface TableRowWrapperProps {
  readonly isExpanded?: boolean;
  readonly onToggleExpanded?: () => void;
  readonly enableExpandableRows?: boolean;
  readonly children: ReactNode;
}

export const TableRowWrapper = ({
  isExpanded,
  onToggleExpanded,
  enableExpandableRows,
  children,
}: TableRowWrapperProps) => {
  const handleRowEvent = (event: React.MouseEvent | React.KeyboardEvent) => {
    if (!enableExpandableRows || !onToggleExpanded) {
      return;
    }

    const interactiveElements = ['BUTTON', 'A', 'INPUT', 'TEXTAREA'];
    if (interactiveElements.includes((event.target as HTMLElement).tagName)) {
      return;
    }

    const isClick = event.nativeEvent instanceof MouseEvent;
    const isEnterOrSpace =
      event.nativeEvent instanceof KeyboardEvent &&
      (event.nativeEvent.key === 'Enter' || event.nativeEvent.key === ' ');

    if (isClick || isEnterOrSpace) {
      onToggleExpanded();
    }
  };

  return (
    <Box
      sx={{
        gridColumn: '1 / -1',
        display: 'grid',
        gridTemplateColumns: 'subgrid',
        px: 3,
        mt: 1,
        transition: 'background-color 0.2s',
        '&:hover': { backgroundColor: enableExpandableRows ? 'greys.100' : undefined },
        '&:focus': { backgroundColor: 'greys.100' },
        borderRadius: 'var(--border-radius-m)',
        backgroundColor: isExpanded ? 'greys.100' : undefined,
        cursor: enableExpandableRows ? 'pointer' : 'default',
      }}
      role={enableExpandableRows ? 'button' : 'presentation'}
      aria-expanded={enableExpandableRows ? isExpanded : undefined}
      onClick={handleRowEvent}
      onKeyDown={handleRowEvent}
      tabIndex={enableExpandableRows ? 0 : -1}
    >
      {children}
    </Box>
  );
};
