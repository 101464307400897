import { useEffect, useMemo, useState } from 'react';
import { Avatar } from '@mui/material';

interface Props {
  readonly name: string;
  readonly email: string;
}

const colors = [
  'blues.500',
  'greens.500',
  'oranges.500',
  'reds.500',
  'blues.700',
  'greens.700',
  'oranges.700',
  'reds.700',
];

const hashName = (name: string) => {
  let hash = 0;
  for (const char of name) {
    hash += char.charCodeAt(0);
  }
  return hash;
};

export const Gravatar = ({ name, email }: Props) => {
  const [url, setUrl] = useState<string | undefined>(undefined);
  const color = useMemo(() => colors[hashName(name) % colors.length], [name]);

  useEffect(() => {
    (async () => {
      const data = new TextEncoder().encode(email);
      const digest = await crypto.subtle.digest('SHA-256', data);
      const hash = Array.from(new Uint8Array(digest))
        .map(b => b.toString(16).padStart(2, '0'))
        .join('');
      setUrl(`https://www.gravatar.com/avatar/${hash}?d=404`);
    })();
  }, [email]);

  const initials = name
    .split(' ')
    .map((name: string) => name[0])
    .join('')
    .toUpperCase();

  return (
    <Avatar alt={name} src={url} sx={{ backgroundColor: color }}>
      {initials}
    </Avatar>
  );
};
