import { cssVar } from 'utils/css';
import { getPrimaryColors } from './utils';
import { Palette } from '@mui/material';

const backgroundColor = '#141719';

export const createDarkPalette = () => {
  const { primary, primaryContrast, primaryLight } = getPrimaryColors(
    'dark',
    cssVar('--color-blue-400'),
    '#000000',
    cssVar('--color-blue-700')
  );

  return {
    mode: 'dark',
    primary: {
      // TODO - need to figure if we are going to allow dark mode for sdk colors
      main: primary,
      contrastText: primaryContrast,
      light: primaryLight,
    },
    secondary: {
      // TODO - not sure how we handle a passed in color from the sdk, im thinking we lighten it if light mode and darken it if dark mode
      main: cssVar('--color-blue-800'),
      contrastText: cssVar('--color-blue-100'),
    },
    tertiary: {
      main: cssVar('--color-blue-800'),
      contrastText: cssVar('--color-blue-100'),
    },
    text: {
      primary: '#FFFFFF',
      secondary: cssVar('--color-grey-400'),
    },
    background: {
      default: backgroundColor,
    },
    //  TDODO
    divider: backgroundColor,
    common: {
      white: cssVar('--color-grey-900'),
      black: '#FFFFFF',
    },
    greys: {
      50: backgroundColor,
      100: cssVar('--color-grey-800'),
      200: cssVar('--color-grey-700'),
      300: cssVar('--color-grey-600'),
      400: cssVar('--color-grey-500'),
      500: cssVar('--color-grey-400'),
      600: cssVar('--color-grey-300'),
      700: cssVar('--color-grey-200'),
      800: cssVar('--color-grey-100'),
      900: '#FFFFFF',
    },
    blues: {
      50: cssVar('--color-blue-900'),
      100: cssVar('--color-blue-800'),
      200: cssVar('--color-blue-700'),
      300: cssVar('--color-blue-600'),
      400: cssVar('--color-blue-500'),
      500: cssVar('--color-blue-400'),
      600: cssVar('--color-blue-300'),
      700: cssVar('--color-blue-200'),
      800: cssVar('--color-blue-100'),
      900: cssVar('--color-blue-50'),
    },
    greens: {
      50: cssVar('--color-green-900'),
      100: cssVar('--color-green-800'),
      200: cssVar('--color-green-700'),
      300: cssVar('--color-green-600'),
      400: cssVar('--color-green-500'),
      500: cssVar('--color-green-400'),
      600: cssVar('--color-green-300'),
      700: cssVar('--color-green-200'),
      800: cssVar('--color-green-100'),
      900: cssVar('--color-green-50'),
    },
    oranges: {
      50: cssVar('--color-orange-900'),
      100: cssVar('--color-orange-800'),
      200: cssVar('--color-orange-700'),
      300: cssVar('--color-orange-600'),
      400: cssVar('--color-orange-500'),
      500: cssVar('--color-orange-400'),
      600: cssVar('--color-orange-300'),
      700: cssVar('--color-orange-200'),
      800: cssVar('--color-orange-100'),
      900: cssVar('--color-orange-50'),
    },
    reds: {
      50: cssVar('--color-red-900'),
      100: cssVar('--color-red-800'),
      200: cssVar('--color-red-700'),
      300: cssVar('--color-red-600'),
      400: cssVar('--color-red-500'),
      500: cssVar('--color-red-400'),
      600: cssVar('--color-red-300'),
      700: cssVar('--color-red-200'),
      800: cssVar('--color-red-100'),
      900: cssVar('--color-red-50'),
    },
    purple: '#CB2CAB',
    violet: '#7C19DE',
  } as Palette;
};
