import { Box, Card, CardHeader, Divider, Skeleton, Stack, Typography } from '@mui/material';
import { ReactNode } from 'react';

interface CardListProps<T> {
  readonly items: T[];
  readonly renderItem: (item: T) => ReactNode;
  readonly renderAction: (item: T) => ReactNode;
  readonly emptyState?: ReactNode;
  readonly loading?: boolean;
  // Minimum height in terms of number of items
  readonly minHeightItems?: number;
}

const MIN_ITEM_HEIGHT = 72;

export const CardList = <T extends { id: string | number; content?: ReactNode }>({
  items,
  renderItem,
  renderAction,
  emptyState,
  loading,
  minHeightItems = 5,
}: CardListProps<T>) => {
  const displayedItems = loading ? Array.from({ length: 5 }, () => null) : items;

  return (
    <Box
      sx={{
        [displayedItems.length ? 'minHeight' : 'height']:
          minHeightItems * MIN_ITEM_HEIGHT + minHeightItems - 1,
      }}
    >
      {displayedItems.length ? (
        <Stack divider={<Divider flexItem />} px={4} flexGrow={1} minWidth={0}>
          {displayedItems.map((item, i) => (
            <Stack
              key={item?.id || `loading-${i}`}
              sx={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                py: 4,
                gap: 4,
                minHeight: MIN_ITEM_HEIGHT,
              }}
            >
              {item ? (
                <>
                  <Box sx={{ minWidth: 0 }}>{renderItem(item)}</Box>
                  {renderAction(item)}
                </>
              ) : (
                <Box>
                  <Skeleton variant="text" sx={{ lineHeight: '1.25rem' }} width={40} />
                  <Skeleton variant="text" sx={{ lineHeight: '1.25rem' }} width={120} />
                </Box>
              )}
            </Stack>
          ))}
        </Stack>
      ) : (
        emptyState
      )}
    </Box>
  );
};
