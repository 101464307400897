import { useTheme } from '@mui/material';

export default function LockCloseDuotone({ selected }: { readonly selected: boolean }) {
  const theme = useTheme();

  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.61242 6.66666C7.93915 6.66665 7.38098 6.66665 6.92055 6.69286C6.45291 6.71948 6.02917 6.77525 5.63669 6.91179C5.54005 6.94541 5.44573 6.98374 5.35329 7.02741C4.33856 7.50676 3.5772 8.4107 3.23051 9.49919C3.08633 9.95188 3.04896 10.4271 3.05537 10.9661C3.06164 11.4931 3.11181 12.1412 3.17447 12.9507L3.18998 13.1517C3.28766 14.4202 3.35363 15.2769 3.67156 15.9765C4.09483 16.9078 4.83584 17.6509 5.76349 18.0404C6.15232 18.2037 6.55611 18.2711 7.00384 18.3028C7.43573 18.3333 7.96223 18.3333 8.60587 18.3333H12.5035C13.1471 18.3333 13.6737 18.3333 14.1055 18.3028C14.5533 18.2711 14.9571 18.2037 15.3459 18.0404C16.2735 17.6509 17.0146 16.9078 17.4378 15.9765C17.7558 15.2769 17.8217 14.4202 17.9194 13.1518L17.9349 12.9507C17.9976 12.1413 18.0478 11.4931 18.054 10.9661C18.0604 10.4271 18.0231 9.95188 17.8789 9.49919C17.5322 8.4107 16.7708 7.50676 15.7561 7.02741C15.6637 6.98374 15.5693 6.94541 15.4727 6.91179C15.0802 6.77525 14.6565 6.71948 14.1888 6.69286C13.7284 6.66665 13.1702 6.66665 12.497 6.66666H8.61242Z"
        fill={selected ? theme.palette.common.white : theme.palette.primary.main}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.94715 11.6667C8.94715 10.8052 9.60993 9.99999 10.5545 9.99999C11.4991 9.99999 12.1619 10.8052 12.1619 11.6667C12.1619 12.2423 11.866 12.7927 11.3879 13.0947V14.1667C11.3879 14.6269 11.0148 15 10.5545 15C10.0943 15 9.72119 14.6269 9.72119 14.1667V13.0947C9.24305 12.7927 8.94715 12.2423 8.94715 11.6667Z"
        fill={theme.palette.primary.light}
      />
      <path
        d="M5.08594 7.16681C5.17295 7.11703 5.26213 7.07051 5.35335 7.02741C5.4458 6.98374 5.54011 6.94541 5.63675 6.91179C5.99896 6.78578 6.38779 6.72857 6.81317 6.69957C7.1584 4.74338 8.74535 3.33332 10.5546 3.33332C12.3638 3.33332 13.9507 4.74337 14.296 6.69954C14.7215 6.72854 15.1105 6.78575 15.4728 6.91179C15.5694 6.94541 15.6637 6.98374 15.7562 7.02741C15.8473 7.07045 15.9363 7.1169 16.0232 7.16661C15.8619 4.15189 13.5277 1.66666 10.5546 1.66666C7.58142 1.66666 5.24712 4.15201 5.08594 7.16681Z"
        fill={theme.palette.primary.light}
      />
      <path
        d="M14.9088 8.48036C14.9143 8.48219 14.9198 8.48405 14.9252 8.48592C14.9305 8.4878 14.9359 8.48969 14.9411 8.4916C14.9303 8.48807 14.9195 8.48432 14.9088 8.48036Z"
        fill={theme.palette.primary.light}
      />
      <path
        d="M6.19678 8.48167C6.18854 8.48469 6.18024 8.48758 6.17188 8.49034C6.176 8.48885 6.18017 8.48738 6.18436 8.48592C6.18847 8.48449 6.19261 8.48308 6.19678 8.48167Z"
        fill={theme.palette.primary.light}
      />
    </svg>
  );
}
