import { Button, Stack, Typography } from '@mui/material';

interface FileBreadcrumbsProps {
  readonly path: string;
  readonly onChange: (path: string) => void;
}

export const FileBreadcrumbs = ({ path, onChange }: FileBreadcrumbsProps) => {
  const breadcrumbs = ['root', ...path.split('/').filter(Boolean)];

  const handleUpdatePath = (index: number) => {
    onChange(breadcrumbs.slice(1, index + 1).join('/'));
  };

  return (
    <Stack
      direction="row"
      divider={
        <Typography variant="caption" fontWeight={600}>
          /
        </Typography>
      }
      gap={0.5}
      pb={4}
    >
      {breadcrumbs.slice(0, -1).map((part, index) => (
        <Button key={part} variant="text" color="primary" onClick={() => handleUpdatePath(index)}>
          <Typography variant="caption" fontWeight={600}>
            {part}
          </Typography>
        </Button>
      ))}
      <Typography variant="caption" fontWeight={600}>
        {breadcrumbs.at(-1)}
      </Typography>
    </Stack>
  );
};
