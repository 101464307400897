import { AxiosFileService } from 'api/axiosService';

export const downloadSitesFile = async (siteId: string, filename: string, directory: string) => {
  const url = `sites/${siteId}/files/download?filename=${filename}&directory=${directory}`;

  const response = await AxiosFileService.get(url, { responseType: 'blob' });

  downloadURL(URL.createObjectURL(response.data), filename);
};

export const downloadURL = (url: string, filename: string) => {
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
};
